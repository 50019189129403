import React, { useState, useContext } from 'react'
import NonMainNavbar from '../../components/NonMainNav'
import { AuthContext } from '../../authContext'
import {
    TextContainer,
    Section,
    SubSection,
    List,
    ListItem,
    ContactInfo,
    LastUpdated,
    ImportantNote,
    DefinitionsSection,
    Definition,
    DefinitionDropdown,
    DropdownHeader,
    DropdownContent
} from '../../PolicyElements.js'

import { FaBookmark } from 'react-icons/fa'

const PrivacyPolicy = () => {
    const [isOpen, setIsOpen] = useState(false)

    const { loggedInUser } = useContext(AuthContext)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    return (
      <div style={{ overflowX: 'hidden', background: 'white' }}>
      <NonMainNavbar loggedInUser={loggedInUser} />
      <TextContainer>
       <h1>Privacy Policy of www.invest.muugen.com</h1>
       <LastUpdated>Last Updated: December 17th, 2024</LastUpdated>
      
      <p>In order to receive information about your Personal Data, the purposes and the parties the Data is shared with, contact the Owner.</p>

      <Section>
        <h2>Owner and Data Controller</h2>
        <ContactInfo>
          <p><b>Muugen Invest</b></p>
          <p>Owner contact email: <b>support@muugen.com</b></p>
        </ContactInfo>
      </Section>

      <Section>
        <h2>Types of Data collected</h2>
        <p>The owner does not provide a list of Personal Data types collected.</p>
        <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection. Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.</p>
        <p>Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.</p>
        <p>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.</p>
        <p>Any use of Cookies – or of other tracking tools — by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy.</p>
        <p>Users are responsible for any third-party Personal Data obtained, published or shared through this Application.</p>
      </Section>

      <Section>
        <h2>Mode and place of processing the Data</h2>
        
        <SubSection>
          <h3>Methods of processing</h3>
          <p>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data. The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</p>
        </SubSection>

        <SubSection>
          <h3>Place</h3>
          <p>The Data is processed at the Owner&apos;s operating offices and in any other places where the parties involved in the processing are located.</p>
          <p>Depending on the User&apos;s location, data transfers may involve transferring the User&apos;s Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</p>
        </SubSection>

        <SubSection>
          <h3>Retention time</h3>
          <p>Unless specified otherwise in this document, Personal Data shall be processed and stored for as long as required by the purpose they have been collected for and may be retained for longer due to applicable legal obligation or based on the Users&apos; consent.</p>
        </SubSection>
      </Section>

      <Section>
        <h2>Cookie Policy</h2>
        <p>This Application uses Trackers. To learn more, Users may consult the Cookie Policy.</p>
      </Section>

      <Section>
        <h2>Further information for Users</h2>
        <p>This part of the document integrates with and supplements the information contained in the rest of the privacy policy and is provided by the business running this Application and, if the case may be, its parent, subsidiaries and affiliates (for the purposes of this section referred to collectively as &quot;we&quot;, &quot;us&quot;, &quot;our&quot;).</p>
        <p>The information contained in this section applies to all Users (Users are referred to below, simply as &quot;you&quot;, &quot;your&quot;, &quot;yours&quot;), who are residents in the following states: California, Virginia, Colorado, Connecticut, Utah, Texas, Oregon, Nevada, Delaware, Iowa, New Hampshire, New Jersey, Nebraska and Montana.</p>
        <p>For such Users, this information supersedes any other possibly divergent or conflicting provisions contained in the privacy policy.</p>
        <p>This part of the document uses the term Personal Information.</p>

        <SubSection>
          <h3>Notice at collection</h3>
          <p>The following Notice at collection provides you with timely notice about the categories of Personal Information collected or disclosed in the past 12 months so that you can exercise meaningful control over our use of that Information.</p>
          <p>While such categorization of Personal Information is mainly based on California privacy laws, it can also be helpful for anyone who is not a California resident to get a general idea of what types of Personal Information are collected.</p>
          <ImportantNote>
            <p>ℹ️ You can read the definitions of these concepts inside the &quot;Definitions and legal references section&quot; of the privacy policy.</p>
          </ImportantNote>
          <p>To know more about your rights you can refer to the &quot;Your privacy rights under US state laws&quot; section of our privacy policy.</p>
          <p>For more details on the collection of Personal Information, please read the section &quot;Detailed information on the processing of Personal Data&quot; of our privacy policy.</p>
          <p>We won&apos;t process your Information for unexpected purposes, or for purposes that are not reasonably necessary to and compatible with the purposes originally disclosed, without your consent.</p>
        </SubSection>

        <SubSection>
          <h3>What are the sources of the Personal Information we collect?</h3>
          <p>We collect the above-mentioned categories of Personal Information, either directly or indirectly, from you when you use this Application.</p>
          <p>For example, you directly provide your Personal Information when you submit requests via any forms on this Application. You also provide Personal Information indirectly when you navigate this Application, as Personal Information about you is automatically observed and collected.</p>
        </SubSection>

        <SubSection>
          <h3>Your privacy rights under US state laws</h3>
          <p>You may exercise certain rights regarding your Personal Information. In particular, to the extent permitted by applicable law, you have:</p>
          <List>
            <ListItem>the right to access Personal Information: the right to know. You have the right to request that we confirm whether or not we are processing your Personal Information. You also have the right to access such Personal Information;</ListItem>
            <ListItem>the right to correct inaccurate Personal Information. You have the right to request that we correct any inaccurate Personal Information we maintain about you;</ListItem>
            <ListItem>the right to request the deletion of your Personal Information. You have the right to request that we delete any of your Personal Information;</ListItem>
            <ListItem>the right to obtain a copy of your Personal Information. We will provide your Personal Information in a portable and usable format that allows you to transfer data easily to another entity – provided that this is technically feasible;</ListItem>
            <ListItem>the right to opt out from the Sale of your Personal Information;</ListItem>
            <ListItem>the right to non-discrimination.</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Additional rights for Users residing in California</h3>
          <p>In addition to the rights listed above common to all Users in the United States, as a User residing in California, you have:</p>
          <List>
            <ListItem>The right to opt out of the Sharing of your Personal Information for cross-context behavioral advertising;</ListItem>
            <ListItem>The right to request to limit our use or disclosure of your Sensitive Personal Information to only that which is necessary to perform the services or provide the goods, as is reasonably expected by an average consumer. Please note that certain exceptions outlined in the law may apply, such as, when the collection and processing of Sensitive Personal Information is necessary to verify or maintain the quality or safety of our service.</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Additional rights for Users residing in Virginia, Colorado, Connecticut, Texas, Oregon, Nevada, Delaware, Iowa, New Hampshire, New Jersey, Nebraska and Montana</h3>
          <p>In addition to the rights listed above common to all Users in the United States, as a User residing in Virginia, Colorado, Connecticut, Texas, Oregon, Nevada, Delaware, Iowa, New Hampshire, New Jersey, Nebraska and Montana you have:</p>
          <List>
            <ListItem>The right to opt out of the processing of your personal information for Targeted Advertising or profiling in furtherance of decisions that produce legal or similarly significant effects concerning you;</ListItem>
            <ListItem>The right to freely give, deny or withdraw your consent for the processing of your Sensitive Personal Information. Please note that certain exceptions outlined in the law may apply, such as, but not limited to, when the collection and processing of Sensitive Personal Information is necessary for the provision of a product or service specifically requested by the consumer.</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Additional rights for users residing in Utah and Iowa</h3>
          <p>In addition to the rights listed above common to all Users in the United States, as a User residing in Utah and Iowa, you have:</p>
          <List>
            <ListItem>The right to opt out of the processing of your Personal Information for Targeted Advertising;</ListItem>
            <ListItem>The right to opt out of the processing of your Sensitive Personal Information. Please note that certain exceptions outlined in the law may apply, such as, but not limited to, when the collection and processing of Sensitive Personal Information is necessary for the provision of a product or service specifically requested by the consumer.</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>How to exercise your privacy rights under US state laws</h3>
          <p>To exercise the rights described above, you need to submit your request to us by contacting us via the contact details provided in this document.</p>
          <p>For us to respond to your request, we must know who you are. We will not respond to any request if we are unable to verify your identity and therefore confirm the Personal Information in our possession relates to you. You are not required to create an account with us to submit your request. We will use any Personal Information collected from you in connection with the verification of your request solely for verification and shall not further disclose the Personal Information, retain it longer than necessary for purposes of verification, or use it for unrelated purposes.</p>
          <p>If you are an adult, you can make a request on behalf of a child under your parental authority.</p>
        </SubSection>

        <SubSection>
          <h3>How to exercise your rights to opt out</h3>
          <p>In addition to what is stated above, to exercise your right to opt-out of Sale or Sharing and Targeted Advertising you can also use the privacy choices link provided on this Application.</p>
          <p>If you want to submit requests to opt out of Sale or Sharing and Targeted Advertising activities via a user-enabled global privacy control, such as for example the Global Privacy Control (&quot;GPC&quot;), you are free to do so and we will abide by such request in a frictionless manner.</p>
        </SubSection>

        <SubSection>
          <h3>How and when we are expected to handle your request</h3>
          <p>We will respond to your request without undue delay, but in all cases within the timeframe required by applicable law. Should we need more time, we will explain to you the reasons why, and how much more time we need.</p>
          <p>Should we deny your request, we will explain to you the reasons behind our denial (where envisaged by applicable law you may then contact the relevant authority to submit a complaint).</p>
          <p>We do not charge a fee to process or respond to your request unless such request is manifestly unfounded or excessive and in all other cases where it is permitted by the applicable law. In such cases, we may charge a reasonable fee or refuse to act on the request. In either case, we will communicate our choices and explain the reasons behind them.</p>
        </SubSection>
      </Section>

      <Section>
        <h2>Additional information about Data collection and processing</h2>
        
        <SubSection>
          <h3>Legal action</h3>
          <p>The User&apos;s Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services. The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.</p>
        </SubSection>

        <SubSection>
          <h3>Additional information about User&apos;s Personal Data</h3>
          <p>In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.</p>
        </SubSection>

        <SubSection>
          <h3>System logs and maintenance</h3>
          <p>For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) or use other Personal Data (such as the IP Address) for this purpose.</p>
        </SubSection>

        <SubSection>
          <h3>Information not contained in this policy</h3>
          <p>More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.</p>
        </SubSection>

        <SubSection>
          <h3>Changes to this privacy policy</h3>
          <p>The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.</p>
          <p>Should the changes affect processing activities performed on the basis of the User&apos;s consent, the Owner shall collect new consent from the User, where required.</p>
        </SubSection>
      </Section>

        <DefinitionsSection>
        <DefinitionDropdown>
            <DropdownHeader onClick={toggleDropdown}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}><FaBookmark />&nbsp; Definitions and legal references</h2>
            <span>{isOpen ? '▼' : '▲'}</span>
            </DropdownHeader>
            
            <DropdownContent isOpen={isOpen}>
            <Definition>
                <strong>Personal Data (or Data) / Personal Information (or Information)</strong>
                <p>Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</p>
            </Definition>

            <Definition>
                <strong>Sensitive Personal Information</strong>
                <p>Sensitive Personal Information means any Personal Information that is not publicly available and reveals information considered sensitive according to the applicable privacy law.</p>
            </Definition>

            <Definition>
                <strong>Usage Data</strong>
                <p>Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server&apos;s answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User&apos;s IT environment.</p>
            </Definition>

            <Definition>
                <strong>User</strong>
                <p>The individual using this Application who, unless otherwise specified, coincides with the Data Subject.</p>
            </Definition>

            <Definition>
                <strong>Data Subject</strong>
                <p>The natural person to whom the Personal Data refers.</p>
            </Definition>

            <Definition>
                <strong>Data Processor (or Processor)</strong>
                <p>The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.</p>
            </Definition>

            <Definition>
                <strong>Data Controller (or Owner)</strong>
                <p>The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</p>
            </Definition>

            <Definition>
                <strong>This Application</strong>
                <p>The means by which the Personal Data of the User is collected and processed.</p>
            </Definition>

            <Definition>
                <strong>Service</strong>
                <p>The service provided by this Application as described in the relative terms (if available) and on this site/application.</p>
            </Definition>

            <Definition>
                <strong>Sale</strong>
                <p>Sale means any exchange of Personal Information by the Owner to a third party, for monetary or other valuable consideration, as defined by the applicable privacy US state law. Please note that the exchange of Personal Information with a service provider pursuant to a written contract that meets the requirements set by the applicable law, does not constitute a Sale of your Personal Information.</p>
            </Definition>

            <Definition>
                <strong>Sharing</strong>
                <p>Sharing means any sharing, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer&apos;s Personal Information by the business to a third party for cross-context behavioral advertising, whether for monetary or other valuable consideration, including transactions between a business and a third party for cross-context behavioral advertising for the benefit of a business in which no money is exchanged, as defined by the California privacy laws. Please note that the exchange of Personal Information with a service provider pursuant to a written contract that meets the requirements set by the California privacy laws, does not constitute sharing of your Personal Information.</p>
            </Definition>

            <Definition>
                <strong>Targeted advertising</strong>
                <p>Targeted advertising means displaying advertisements to a consumer where the advertisement is selected based on Personal Information obtained from that consumer&apos;s activities over time and across nonaffiliated websites or online applications to predict such consumer&apos;s preferences or interests, as defined by the applicable privacy US state law.</p>
            </Definition>

            <Definition>
                <strong>Legal information</strong>
                <p>This privacy policy relates solely to this Application, if not stated otherwise within this document.</p>
            </Definition>
            </DropdownContent>
        </DefinitionDropdown>
        </DefinitionsSection>
      </TextContainer>
      </div>
    )
  }
  
  export default PrivacyPolicy


// # Privacy Policy

// Last Updated: [Date]

// ## 1. Introduction

// Welcome to Muugen Invest ("we," "our," or "us"). We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [Your Website URL] (the "Site").

// ## 2. Information We Collect

// ### 2.1 Personal Information
// We may collect personal information that you voluntarily provide to us when you:
// - Register as an investor on our platform
// - Express interest in film investment opportunities
// - Make an investment
// - Complete investor accreditation verification
// - Sign up for investment updates and notifications
// - Contact us about investment opportunities
// - Participate in investor surveys or research

// This information may include:
// - Name and contact information
// - Financial information for accreditation verification
// - Investment preferences and history
// - Bank account or wire transfer information
// - Tax identification information
// - Investment entity information (if applicable)
// - Professional background and investment experience
// - Communication preferences
// - Device and usage information

// ### 2.2 Information Automatically Collected
// When you visit our Site, we automatically collect certain information about your device, including:
// - IP address
// - Browser type
// - Operating system
// - Access times
// - Pages visited
// - Referring website addresses

// ## 3. How We Use Your Information

// We use the information we collect to:
// - Verify your accredited investor status
// - Process and manage your film investments
// - Provide updates on investment opportunities and portfolio performance
// - Distribute investment returns and tax documents
// - Send administrative information about your investments
// - Share film project updates and investor communications
// - Send marketing communications about new investment opportunities (with your consent)
// - Comply with securities regulations and reporting requirements
// - Improve our investment platform and services
// - Respond to your inquiries about investments
// - Protect against fraud and unauthorized access
// - Conduct investor research and analysis
// - Meet our legal obligations as an investment platform

// ## 4. Information Sharing and Third-Party Data Usage

// ### 4.1 Information We Share
// We may share your information with:
// - Film production companies you invest in (limited to necessary investment information)
// - Financial institutions processing investments and distributions
// - Investor accreditation verification services
// - Tax preparation and reporting services
// - Legal and compliance service providers
// - Securities regulators and other legal authorities when required by law
// - Third parties in connection with a business transfer
// - Other parties with your explicit consent

// ### 4.2 Third-Party Data Usage
// Third parties who receive your information may use it for their own purposes, including:

// - Film production companies may use investor information for:
//   - Project updates and communications
//   - Investor relations
//   - Required securities filings and disclosures
//   - Marketing and promotional materials (with consent)

// - Financial institutions may use your information for:
//   - Account setup and maintenance
//   - Transaction processing
//   - Anti-fraud measures
//   - Regulatory compliance
//   - Their own marketing purposes (subject to their privacy policies)

// - Verification services may use your information for:
//   - Background checks
//   - Accreditation verification
//   - Fraud prevention
//   - Regulatory compliance
//   - Updating their verification databases

// ### 4.3 Third-Party Privacy Policies
// Each third party has their own privacy policy and data handling practices. We encourage you to review their privacy policies when their services are used. While we carefully select our partners and require them to protect your information, we cannot control how they use information once shared.

// ### 4.4 Data Protection Measures
// We implement strict data sharing protocols to ensure your financial and personal information is protected. All service providers are bound by confidentiality agreements and data protection requirements. However, once information is shared with third parties, their privacy policies and practices will apply to their use of your information.

// We do not sell your personal information to third parties.

// ## 5. Your Privacy Rights

// ### 5.1 CCPA/CPRA Rights (California Residents)
// If you are a California resident, you have the right to:
// - Know what personal information we collect and how we use it
// - Delete your personal information
// - Opt-out of the sale of your personal information
// - Non-discrimination for exercising your rights
// - Correct inaccurate personal information
// - Limit use and disclosure of sensitive personal information

// ### 5.2 CalOPPA Compliance
// This Site honors Do Not Track signals and does not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place.

// ## 6. Data Security

// We implement appropriate technical and organizational measures to protect your personal information, including:
// - Encryption of sensitive data
// - Regular security assessments
// - Access controls
// - Secure data storage

// ## 7. Children's Privacy

// Our Site is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13.

// ## 8. Changes to This Privacy Policy

// We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Last Updated" date.

// ## 9. Contact Us

// If you have questions about this Privacy Policy or our privacy practices, please contact us at:

// [Your Company Name]
// Email: [Privacy Contact Email]
// Address: [Your Business Address]
// Phone: [Contact Number]

// ## 10. Your Choices and Rights

// You may:
// - Update or correct your personal information
// - Opt-out of marketing communications
// - Request deletion of your personal information
// - Choose whether to accept cookies
// - Exercise your privacy rights under applicable laws

// To exercise these rights, please contact us using the information provided in Section 9.