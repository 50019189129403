import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../../config'

import { getFollowing, getFollowers, getFollowedArtists } from '../investmentProfileApi'

import {
    StyledSelect,
    FollowBarContainer,
    FollowButton,
    FollowWrapper,
    ShowMoreButton,
    ShowMoreWrapper
} from './FollowerElements'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../LoadingElement'

import {
    NoInfo
} from '../InvestmentProfileElements'

import FollowCard from './FollowCard'
import ArtistFollowCard from './ArtistFollowCard'

const FollowerBar = ({ following }) => {
    const [selectedOption, setSelectedOption] = useState('following')
    const [followingArray, setFollowingArray] = useState({
        followedUsers: [],
        hasMore: null,
        pagination: { next: { page: 1, limit: 2 } },
        totalFollowedArtists: null 
    })
    const [followersArray, setFollowersArray] = useState({
        followers: [],
        hasMore: null,
        pagination: { next: { page: 1, limit: 2 } },
        totalFollowedArtists: null 
    })
    const [followingArtistArray, setFollowingArtistArray] = useState({
        followedArtists: [],
        hasMore: null,
        pagination: { next: { page: 1, limit: 2 } },
        totalFollowedArtists: null 
    })
    const [followingStatusMap, setFollowingStatusMap] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [selection, setSelection] = useState('Investors')
    // const [limit] = useState(10)

    const fetchFollowingStatus = async (users) => {
        try {
            const userIds = users.map(user => user._id)
            const response = await axios.post(
                `${API_URL}/user-profile-page/isFollowing/batch`,
                { userIds },
                { withCredentials: true }
            )
            
            setFollowingStatusMap(prevMap => ({
                ...prevMap,
                ...response.data.followingStatus
            }))
        } catch (error) {
            console.error('Error fetching following status:', error)
        }
    }

    useEffect(() => {
        const runGetFollowing = async () => {
            try {
                if (followingArray?.pagination?.next?.page === 1) {
                    setIsLoading(true)

                    const response = await getFollowing(followingArray?.pagination?.next?.page, followingArray?.pagination?.next?.limit)

                    setFollowingArray(response)

                    if (response.following.length) {
                        await fetchFollowingStatus(response.following)
                    }
                    setIsLoading(false)
                } 
            } catch (e) {
                console.log(e)
                setIsLoading(false)
            }
        }
    
        if (selectedOption === 'following') {
          runGetFollowing()
        }
        
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const runGetFollowers = async () => {
            try {
                if (followersArray?.pagination?.next?.page === 1) {
                    setIsLoading(true)

                    const response = await getFollowers(followersArray?.pagination?.next?.page, followersArray?.pagination?.next?.limit)

                    setFollowersArray(response)
                    if (response.followers?.length) {
                        await fetchFollowingStatus(response.followers)
                    }
                    setIsLoading(false)
                }
            } catch (e) {
                setIsLoading(false)
                console.log(e)
            }
        }
      
        if (selectedOption === 'followers') {
          runGetFollowers()
        }

        // eslint-disable-next-line
    }, [selectedOption])

    useEffect(() => {
        const getFollowingArtists = async () => {
            try {
                if (followingArtistArray?.pagination?.next?.page === 1) {
                    setIsLoading(true)
                    const response = await getFollowedArtists(followingArtistArray?.pagination?.next?.page)

                    setFollowingArtistArray(response)
                    setIsLoading(false)
                }
            } catch (e) {
                setIsLoading(false)
                console.log(e)
            }
        }

        if (selection === 'Artists') {
            getFollowingArtists()
        }

        // eslint-disable-next-line
    }, [selection])

    const handleChange = (event) => {
        setSelection(event.target.value)
    }

    const handleShowMore = async () => {
        const response = await getFollowing(followingArray?.pagination?.next?.page, followingArray?.pagination?.next?.limit)
        await fetchFollowingStatus(response.followedUsers)

        setFollowingArray(prevFollowingArray => {
            const updatedFollowingArray = {
                followedUsers: [...prevFollowingArray.followedUsers, ...response.followedUsers],
                hasMore: response.hasMore,
                pagination: response.pagination,
                totalFollowedUsers: response.totalFollowedUsers,
                followersCount: response.followersCount
            }

            return updatedFollowingArray
        })
    }

    const handleShowMoreFollowers = async () => {
        const response = await getFollowers(followersArray?.pagination?.next?.page, followersArray?.pagination?.next?.limit)
        await fetchFollowingStatus(response.followers)

        setFollowersArray(prevFollowersArray => {
            const updatedFollowersArray = {
                followers: [...prevFollowersArray.followers, ...response.followers],
                totalFollowers: response.totalFollowers,
                followersCount: prevFollowersArray.followersCount,
                pagination: response.pagination,
                hasMore: response.hasMore
            }

            return updatedFollowersArray
        })
    }

    // Update following status in the map
    const updateFollowingStatus = (userId, status) => {
        setFollowingStatusMap(prevMap => ({
            ...prevMap,
            [userId]: status
        }))
    }

    const handleShowMoreArtistFollowers = async () => {
        const response = await getFollowedArtists(followingArtistArray?.pagination?.next?.page)

        setFollowingArtistArray(prevArtistArray => {
            const updatedFollowersArray = {
                followedArtists: [...prevArtistArray.followedArtists, ...response.followedArtists],
                hasMore: response.hasMore,
                pagination: response.pagination,
                totalFollowedArtists: response.totalFollowedArtists
            }

            return updatedFollowersArray
        })
    }

    const handleArtistUnfollow = (artistId) => {
        setFollowingArtistArray(prevState => ({
            ...prevState,
            followedArtists: prevState.followedArtists.filter(
                artist => artist.artist._id !== artistId
            ),
            totalFollowedArtists: prevState.totalFollowedArtists - 1
        }))
    }

    const followingMapping = followingArray?.following?.map((follow, index) => {
        return (
            <FollowCard 
                type={'following'} 
                setFollowingArray={setFollowingArray} 
                follow={follow} 
                key={follow?.username} 
                index={index} 
                isFollowing={followingStatusMap[follow._id]}
                updateFollowingStatus={updateFollowingStatus}
            />
        )
    })

    const followersMapping = followersArray?.followers?.map((follow, index) => {
        return (
            <FollowCard 
                type={'followers'} 
                follow={follow} 
                key={follow?.username} 
                index={index} 
                isFollowing={followingStatusMap[follow._id]}
                updateFollowingStatus={updateFollowingStatus}
            />
        )
    })

    const followingArtistMapping = followingArtistArray?.followedArtists?.map((follow, index) => {
        return (
            <ArtistFollowCard 
                follow={follow} 
                key={follow?.artist?.slug} 
                index={index} 
                onUnfollow={handleArtistUnfollow}
            />
        )
    })

    const formatCount = (count) => {
        if (!count) return '0'
        
        // Handle millions
        if (count >= 1000000) {
            const millions = (count / 1000000).toFixed(1)
            // Remove trailing .0 if present
            return `${parseFloat(millions)}M`
        }
        
        // Handle thousands
        if (count >= 1000) {
            const thousands = (count / 1000).toFixed(1)
            // Remove trailing .0 if present
            return `${parseFloat(thousands)}K`
        }
        
        return count.toString()
    }

    return (
        <div style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 50 }}>
            {selection === 'Investors' ? (
                <>
                    <StyledSelect onChange={handleChange}>
                        <option value="Investors">Investors</option>
                        <option value="Artists">Artists</option>
                    </StyledSelect>
                    <FollowBarContainer>
                        <FollowButton selected={selectedOption === 'following'} onClick={() => setSelectedOption('following')} >Following ({formatCount(followingArray?.totalFollowing)})</FollowButton>
                        <FollowButton style={{ marginLeft: 10 }} selected={selectedOption === 'followers'} onClick={() => setSelectedOption('followers')}>Followers ({formatCount(followingArray?.followerCount)})</FollowButton>
                    </FollowBarContainer>
                    {isLoading ? (
                        <LoadContainer>
                            <div style={{position: "relative"}}>
                                <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                                    <OuterPath cx="25" cy="25" r="20" fill="none" />
                                </OuterSpinner>
                                <Spinner width="50" height="50" viewBox="0 0 50 50">
                                    <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                                </Spinner>
                            </div>
                        </LoadContainer>
                    ) : (
                        <>
                            <FollowWrapper>
                                {selectedOption === 'following' ? followingMapping : followersMapping}
                            </FollowWrapper>
                            {selectedOption === 'following' && followingArray.hasMore && (
                                <ShowMoreWrapper>
                                    <ShowMoreButton onClick={handleShowMore}>Show More</ShowMoreButton>
                                </ShowMoreWrapper>
                            )}
                            {selectedOption === 'followers' && followersArray.hasMore && (
                                <ShowMoreWrapper>
                                    <ShowMoreButton onClick={handleShowMoreFollowers}>Show More</ShowMoreButton>
                                </ShowMoreWrapper>
                            )}
                        </>
                    )}
                </>
            ) : (
                <>
                    <StyledSelect onChange={handleChange}>
                        <option value="Investors">Investors</option>
                        <option value="Artists">Artists</option>
                    </StyledSelect>
                    <FollowBarContainer>
                        <FollowButton selected={selectedOption === 'following'} onClick={() => setSelectedOption('following')} >Following ({followingArtistArray?.totalFollowedArtists})</FollowButton>
                    </FollowBarContainer>
                    {isLoading ? (
                        <LoadContainer>
                            <div style={{position: "relative"}}>
                                <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                                    <OuterPath cx="25" cy="25" r="20" fill="none" />
                                </OuterSpinner>
                                <Spinner width="50" height="50" viewBox="0 0 50 50">
                                    <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                                </Spinner>
                            </div>
                        </LoadContainer>
                    ) : (
                        <>
                            <FollowWrapper>
                                {followingArtistMapping?.followedArtists?.length === 0 ? <NoInfo>No Artists Followed</NoInfo> : followingArtistMapping}
                            </FollowWrapper>
                            {followingArtistArray?.hasMore && (
                                <ShowMoreWrapper>
                                    <ShowMoreButton onClick={handleShowMoreArtistFollowers}>Show More</ShowMoreButton>
                                </ShowMoreWrapper>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default FollowerBar