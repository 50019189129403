import axios from 'axios'
import { API_URL } from '../../config'

export const getWatchlist = async (page) => {
    try {
        const response = await axios.get(`${API_URL}/investment-profile/watchlist/me?page=${page}`, { withCredentials: true })

        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const getInvestmentInfo = async () => {
    try {
        const response = await axios.get(`${API_URL}/user-profile/investment-info/me`, { withCredentials: true })
        
        return response.data
    } catch (e) {
        console.error(e)
    }
}

//INVESTMENTS
export const toggleAnonymous = async (investmentId) => {
    try {
        const response = await axios.post(`${API_URL}/investment-profile/toggle-investment-anonymous/${investmentId}`, {}, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const cancelInvestment = async (investmentId) => {
    try {
        const response = await axios.post(`${API_URL}/investment-profile/cancel-investment/${investmentId}`, {}, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

//FOLLOWERS
export const getFollowing = async (page, limit) => {
    const response = await axios.get(`${API_URL}/investment-profile/following/me?page=${page}&limit=${limit}`, { withCredentials: true })

    return response.data
}

export const getFollowers = async (page, limit) => {
    const response = await axios.get(`${API_URL}/investment-profile/followers/me?page=${page}&limit=${limit}`, { withCredentials: true })

    return response.data
}

export const getFollowedArtists = async (page) => {
    try {
        const response = await axios.get(`${API_URL}/investment-profile/followed-artists/me?page=${page}&limit=${10}`, { withCredentials: true })

        return response.data
    } catch (e) {
        console.error(e)
    }
}

export const followUser = async (followedUserId) => {
    try {
        const response = await axios.post(`${API_URL}/investment-profile/follow/${followedUserId}`, {}, { withCredentials: true })

        console.log(response.data)

        return response 
    } catch (e) {
        console.error(e)
    }
}

export const getExpressedInterest = async (page) => {
    try {
        const response = await axios.get(`${API_URL}/investment-profile/expressed-interest/me?page=${page}`, { withCredentials: true })

        return response.data
    } catch (e) {
        console.error(e)
    }
}

//PROFILE
export const submitApi = async (route, payload) => {
    try {
        const res = await axios.post(`${API_URL}/user-profile/${route}/me`, payload, { withCredentials: true })

        return res
    } catch (e) {
        console.error(e)
    }
}

export const imageSubmitApi = async (formData) => {
    try {
        const res = await axios.post(`${API_URL}/user-profile/profile-picture/me`, formData, { withCredentials: true })

        return res
    } catch (e) {
        console.error(e)
    } 
}