import React, { useContext, useState } from 'react'
import NonMainNavbar from '../../components/NonMainNav'
import { AuthContext } from '../../authContext'
import {
    TextContainer,
    Section,
    SubSection,
    List,
    ListItem,
    ContactInfo,
    LastUpdated,
    // ImportantNote,
    DefinitionsSection,
    Definition,
    DefinitionDropdown,
    DropdownHeader,
    DropdownContent
} from '../../PolicyElements'

import { FaBookmark } from 'react-icons/fa'

const TermsOfService = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const { loggedInUser } = useContext(AuthContext)

  return (
    <div style={{ overflowX: 'hidden', background: 'white' }}>
      <NonMainNavbar loggedInUser={loggedInUser} />
      <TextContainer>
      <h1>Terms and Conditions of www.invest.muugen.com</h1>
      <LastUpdated>Last Updated: December 17th, 2024</LastUpdated>

      <Section>
        <h2>Introduction</h2>
        <p>These Terms govern</p>
        <List>
          <ListItem>the use of this Application, and,</ListItem>
          <ListItem>any other related Agreement or legal relationship with the Owner</ListItem>
        </List>
        <p>in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</p>
        <p>The User must read this document carefully.</p>
      </Section>

      <Section>
        <h2>This Application is provided by:</h2>
        <ContactInfo>
          <p><b>Muugen Invest</b></p>
          <p>Owner contact email: <b>support@muugen.com</b></p>
        </ContactInfo>
      </Section>

      <Section>
        <h2>&quot;This Application&quot; refers to</h2>
        <List>
          <ListItem>this website, including its subdomains and any other website through which the Owner makes its Service available;</ListItem>
          <ListItem>applications for mobile, tablet and other smart device systems;</ListItem>
          <ListItem>the Application Program Interfaces (API);</ListItem>
          <ListItem>the Service;</ListItem>
          <ListItem>any applications, sample and content files, source code, scripts, instruction sets or software included as part of the Service, as well as any related documentation;</ListItem>
        </List>
      </Section>

      <Section>
        <h2>What the User should know at a glance</h2>
        <p>Usage of this Application and the Service is age restricted: to access and use this Application and its Service the User must be an adult under applicable law.</p>
        <p>Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.</p>
      </Section>

      <Section>
        <h2>TERMS OF USE</h2>
        <p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application.</p>
        <p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>
        <p>By using this Application, Users confirm to meet the following requirements:</p>
        <List>
          <ListItem>There are no restrictions for Users in terms of being Consumers or Business Users;</ListItem>
          <ListItem>Users must be recognized as adult by applicable law;</ListItem>
        </List>
      </Section>

      <Section>
        <h2>Account registration</h2>
        <p>To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.</p>
        <p>Failure to do so will cause unavailability of the Service.</p>
        <p>Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Application.</p>
        <p>By registering, Users agree to be fully responsible for all activities that occur under their username and password.</p>
        <p>Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</p>
      </Section>

      <Section>
        <h2>Account termination</h2>
        <p>Users can terminate their account and stop using the Service subject to the conditions and according to the procedures outlined in the relevant section of this Application.</p>
      </Section>

      <Section>
        <h2>Account suspension and deletion</h2>
        <p>The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts that it deems inappropriate, offensive or in violation of these Terms.</p>
        <p>The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.</p>
        <p>The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</p>
      </Section>

      <Section>
        <h2>Content on this Application</h2>
        <p>Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.</p>
        <p>The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.</p>
        <p>In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
      </Section>

      <Section>
        <h2>Rights regarding content on this Application - All rights reserved</h2>
        <p>The Owner holds and reserves all intellectual property rights for any such content.</p>
        <p>Users may not, therefore, use such content in any way that is not necessary or implicit in the proper use of the Service.</p>
        <p>In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Application, nor allow any third party to do so through the User or their device, even without the User&apos;s knowledge.</p>
        <p>Where explicitly stated on this Application, the User may download, copy and/or share some content available through this Application for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.</p>
        <p>Any applicable statutory limitation or exception to copyright shall stay unaffected.</p>
      </Section>

      <Section>
        <h2>Content provided by Users</h2>
        <p>The Owner allows Users to upload, share or provide their own content to this Application.</p>
        <p>By providing content to this Application, Users confirm that they are legally allowed to do so and that they are not infringing any statutory provisions and/or third-party rights.</p>
      </Section>

      <Section>
        <h2>Rights regarding content provided by Users</h2>
        <p>Users acknowledge and accept that by providing their own content on this Application they grant the Owner a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of this Application as contractually required.</p>
        <p>To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to this Application.</p>
        <p>Users acknowledge, accept and confirm that all content they provide through this Application is provided subject to the same general conditions set forth for content on this Application.</p>
      </Section>

      <Section>
        <h2>Liability for provided content</h2>
        <p>Users are solely liable for any content they upload, post, share, or provide through this Application. Users acknowledge and accept that the Owner does not filter or moderate such content.</p>
        <p>However, the Owner reserves the right to remove, delete or block such content at its own discretion and, without prior notice, to deny the uploading User access to this Application:</p>
        <List>
          <ListItem>upon becoming aware of any (alleged) violation of these Terms, any third-party rights, or applicable law, based on such content;</ListItem>
          <ListItem>if a notice of infringement of intellectual property rights is received;</ListItem>
          <ListItem>if a notice of violation of a third party&apos;s privacy, including their intimate privacy, is received;</ListItem>
          <ListItem>upon order of a public authority; or</ListItem>
          <ListItem>where the Owner is made aware that the content, while being accessible via this Application, may represent a risk for Users, third parties and/or the availability of the Service.</ListItem>
        </List>
        <p>The removal, deletion or blocking of content shall not entitle Users that have provided such content or that are liable for it, to any claims for compensation, damages or reimbursement.</p>
        <p>Users agree to hold the Owner harmless from and against any claim asserted and/or damage suffered due to content they provided to or provided through this Application.</p>
      </Section>

      <Section>
        <h2>Access to external resources</h2>
        <p>Through this Application Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>
        <p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties&apos; terms and conditions or, in the absence of those, applicable statutory law.</p>
      </Section>

      <Section>
        <h2>Acceptable use</h2>
        <p>This Application and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>
        <p>Users are solely responsible for making sure that their use of this Application and/or the Service violates no applicable law, regulations or third-party rights.</p>
        <p>Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Application or the Service, terminating contracts, reporting any misconduct performed through this Application or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:</p>
        <List>
          <ListItem>violate laws, regulations and/or these Terms;</ListItem>
          <ListItem>infringe any third-party rights;</ListItem>
          <ListItem>considerably impair the Owner&apos;s legitimate interests;</ListItem>
          <ListItem>offend the Owner or any third party.</ListItem>
        </List>
      </Section>

      <Section>
        <h2>API usage terms</h2>
        <p>Users may access their data relating to this Application via the Application Program Interface (API). Any use of the API, including use of the API through a third-party product/service that accesses this Application, is bound by these Terms and, in addition, by the following specific terms:</p>
        <List>
          <ListItem>the User expressly understands and agrees that the Owner bears no responsibility and shall not be held liable for any damages or losses resulting from the User&apos;s use of the API or their use of any third-party products/services that access data through the API.</ListItem>
        </List>
      </Section>

      <Section>
        <h2>Regulation Crowdfunding Portal Specific Terms</h2>
        
        <SubSection>
          <h3>Funding Portal Activities and Limitations</h3>
          
          <h4>Permitted Activities</h4>
          <p>This Application operates as a funding portal registered with the SEC and FINRA to facilitate the offer and sale of securities under Regulation Crowdfunding. As a funding portal, we:</p>
          <List>
            <ListItem>Allow companies to offer and sell securities through our platform</ListItem>
            <ListItem>Provide communication channels for potential investors to communicate with issuers</ListItem>
            <ListItem>Facilitate the investment commitment process</ListItem>
            <ListItem>Direct the transmission of funds between investors and issuers</ListItem>
            <ListItem>Provide educational materials about securities offerings</ListItem>
          </List>

          <h4>Regulatory Limitations</h4>
          <p>As a funding portal, we are prohibited from:</p>
          <List>
            <ListItem>Offering investment advice or recommendations</ListItem>
            <ListItem>Soliciting purchases, sales, or offers to buy securities offered on our platform</ListItem>
            <ListItem>Compensating employees or agents for soliciting securities offerings</ListItem>
            <ListItem>Holding, managing, possessing, or handling investor funds or securities</ListItem>
            <ListItem>Having a financial interest in any issuer using our services</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Investor Requirements and Limitations</h3>

          <h4>Investment Limits</h4>
          <p>Users acknowledge that there are limits on how much they can invest during any 12-month period through all Regulation Crowdfunding offerings. These limits depend on your annual income and net worth:</p>
          <List>
            <ListItem>If either your annual income or net worth is less than $124,000, you can invest the greater of:
              <List>
                <ListItem>$2,500; or</ListItem>
                <ListItem>5% of the lesser of your annual income or net worth</ListItem>
              </List>
            </ListItem>
            <ListItem>If both your annual income and net worth are equal to or more than $124,000, you can invest up to 10% of the lesser of your annual income or net worth</ListItem>
            <ListItem>During the 12-month period, your total investments in all Regulation Crowdfunding offerings cannot exceed $124,000</ListItem>
          </List>

          <h4>Self-Certification</h4>
          <p>Users must self-certify their income, net worth, and aggregate Regulation Crowdfunding investments for the past 12 months before making an investment commitment.</p>
        </SubSection>

        <SubSection>
          <h3>Issuer Requirements</h3>

          <h4>Disclosure Requirements</h4>
          <p>Issuers using our platform must:</p>
          <List>
            <ListItem>File specific disclosure documents with the SEC</ListItem>
            <ListItem>Provide complete and accurate information about their offering</ListItem>
            <ListItem>Update investors about material changes</ListItem>
            <ListItem>Comply with ongoing reporting requirements</ListItem>
          </List>

          <h4>Offering Limitations</h4>
          <p>Issuers may raise a maximum of $5 million through Regulation Crowdfunding offerings in a 12-month period.</p>
        </SubSection>

        <SubSection>
          <h3>Investment Process</h3>

          <h4>Investment Commitments</h4>
          <List>
            <ListItem>Users may make investment commitments only after reviewing issuer disclosures</ListItem>
            <ListItem>Users have the right to cancel their investment commitment for any reason until 48 hours prior to the offering deadline</ListItem>
            <ListItem>If there is a material change to an offering, Users will be notified and must reconfirm their investment within 5 business days</ListItem>
          </List>

          <h4>Early Completion</h4>
          <p>If an offering reaches its target amount prior to the deadline, the issuer may close the offering early if:</p>
          <List>
            <ListItem>The offering has been open for a minimum of 21 days</ListItem>
            <ListItem>Investors are given notice of the new deadline</ListItem>
            <ListItem>Investors maintain their right to cancel until 48 hours prior to the new deadline</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Educational Materials</h3>

          <h4>Required Review</h4>
          <p>Users must review our educational materials and demonstrate their understanding of:</p>
          <List>
            <ListItem>The process for investing in a Regulation Crowdfunding offering</ListItem>
            <ListItem>The risks associated with investing in securities offered under Regulation Crowdfunding</ListItem>
            <ListItem>Investment limitations</ListItem>
            <ListItem>Resale restrictions</ListItem>
            <ListItem>Investor&apos;s rights to cancel an investment commitment</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Risk Disclosures</h3>

          <h4>Investment Risks</h4>
          <p>Users acknowledge that investments in startups and early-stage companies through Regulation Crowdfunding:</p>
          <List>
            <ListItem>Are highly speculative and involve a high degree of risk</ListItem>
            <ListItem>Are illiquid with limited resale opportunities</ListItem>
            <ListItem>May result in a complete loss of investment</ListItem>
            <ListItem>Should only represent a small portion of an investor&apos;s portfolio</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Communications</h3>

          <h4>Platform Communications</h4>
          <List>
            <ListItem>All communications regarding offerings must occur through the platform&apos;s communication channels</ListItem>
            <ListItem>Issuers must respond to questions and comments in the communication channels</ListItem>
            <ListItem>Promotional activities must comply with SEC regulations regarding tombstone ads</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Compliance</h3>

          <h4>Anti-Money Laundering</h4>
          <p>Users agree to provide any information necessary for the platform to comply with anti-money laundering regulations and verify investor identity.</p>

          <h4>Bad Actor Disqualification</h4>
          <p>Issuers must certify that they are not subject to &quot;bad actor&quot; disqualification under Securities regulations.</p>
        </SubSection>

        <SubSection>
          <h3>Service Providers</h3>

          <h4>Third-Party Service Providers</h4>
          <p>The platform may engage qualified third-party service providers to:</p>
          <List>
            <ListItem>Verify investor identity</ListItem>
            <ListItem>Perform background checks on issuers</ListItem>
            <ListItem>Process payments</ListItem>
            <ListItem>Provide escrow services</ListItem>
            <ListItem>Maintain records</ListItem>
          </List>
        </SubSection>

        <SubSection>
          <h3>Records Retention</h3>

          <h4>Record Keeping</h4>
          <p>The platform will maintain records of all offerings and transactions for the period required by SEC regulations, including:</p>
          <List>
            <ListItem>Records of investors</ListItem>
            <ListItem>Records of issuers</ListItem>
            <ListItem>Communications</ListItem>
            <ListItem>Investment commitments</ListItem>
            <ListItem>Transactions</ListItem>
          </List>
        </SubSection>
      </Section>

      <Section>
        <h2>Liability and indemnification</h2>
        <p>Unless otherwise explicitly stated or agreed with Users, the Owner&apos;s liability for damages in connection with the execution of the Agreement shall be excluded, limited and/or reduced to the maximum extent permitted by applicable law.</p>
      </Section>

      <Section>
        <h2>Indemnification</h2>
        <p>The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees harmless from and against any claim or demand — including but not limited to lawyer&apos;s fees and costs — made by any third party due to or in relation with any culpable violation of these Terms, third-party rights or statutory provisions connected to the use of the Service by the User or its affiliates, officers, directors, agents, co-branders, partners and employees to the extent allowed by applicable law.</p>
        <p>The above also applies to any claims exercised by third parties (including but not limited to the Owner&apos;s clients or customers) against the Owner related to Digital Products provided by the User such as, for instance, conformity claims.</p>
      </Section>

      <Section>
        <h2>Limitation of liability</h2>
        <p>Unless otherwise explicitly stated and without prejudice to applicable law, Users shall have no right to claim damages against the Owner (or any natural or legal person acting on its behalf).</p>
        <p>This does not apply to damages to life, health or physical integrity, damages resulting from the breach of material contractual obligations such as any obligation strictly necessary to achieve the purpose of the contract, and/or damages resulting from intent or gross negligence, as long as this Application has been appropriately and correctly used by the User.</p>
        <p>Unless damages have been caused by way of intent or gross negligence, or they affect life, health or physical integrity, the Owner shall only be liable to the extent of typical and foreseeable damages at the moment the contract was entered into.</p>
      </Section>

      <Section>
        <h2>US Users</h2>
        <SubSection>
          <h3>Disclaimer of Warranties</h3>
          <p><b>This Application is provided strictly on an “as is” and “as available” basis.  Use of the Service is at Users’ own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by the User from the Owner or through the Service will create any warranty not expressly stated herein.</b></p>
          <p><b>Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users’ requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at Users&apos; own risk and Users shall be solely responsible for any damage to Users’ computer system or mobile device or loss of data that results from such download or Users’ use of the Service.</b></p>
          <p><b>The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.</b></p>
          <p><b>The Service may become inaccessible or it may not function properly with Users’ web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.</b></p>
          <p><b>Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.</b></p>
        </SubSection>
        <SubSection>
          <h3>Limitations of liability</h3>
          <p><b>To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for:</b></p>
          <ListItem><b>any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and</b></ListItem>
          <ListItem><b>any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;</b></ListItem>
          <ListItem><b>any errors, mistakes, or inaccuracies of content;</b></ListItem>
          <ListItem><b>personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;</b></ListItem>
          <ListItem><b>any unauthorized access to or use of the Owner’s secure servers and/or any and all personal information stored therein;</b></ListItem>
          <ListItem><b>any interruption or cessation of transmission to or from the Service;</b></ListItem>
          <ListItem><b>any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;</b></ListItem>
          <ListItem><b>any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or</b></ListItem>
          <ListItem><b>the defamatory, offensive, or illegal conduct of any User or third party.</b></ListItem>
          <p><b>In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.</b></p>
          <p><b>This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if the User has been advised of the possibility of such damage.</b></p>
          <p><b>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to the User.  The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction.  The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.</b></p>
        </SubSection>
        <SubSection>
          <h3>Indemnification</h3>
          <p><b>The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from</b></p>
          <ListItem><b>User’s use of and access to the Service, including any data or content transmitted or received by User;</b></ListItem>
          <ListItem><b>User’s violation of these terms, including, but not limited to, User’s breach of any of the representations and warranties set forth in these terms;</b></ListItem>
          <ListItem><b>User’s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;</b></ListItem>
          <ListItem><b>User’s violation of any statutory law, rule, or regulation;</b></ListItem>
          <ListItem><b>any content that is submitted from User’s account, including third party access with User’s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;</b></ListItem>
          <ListItem><b>User’s wilful misconduct; or</b></ListItem>
          <ListItem><b>statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.</b></ListItem>
        </SubSection>
      </Section>
      <Section>
        <h2>Common Provisions</h2>
        <SubSection>
          <h3>No Waiver</h3>
          <p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision.  No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
        </SubSection>
        <SubSection>
          <h3>Service interruption</h3>
          <p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>
          <p>Within the limits of law, the Owner may also decide to suspend or discontinue the Service altogether. If the Service is discontinued, the Owner will cooperate with Users to enable them to withdraw personal data or information and will respect Users&apos; rights relating to continued product use and/or compensation, as provided for by applicable law.</p>
          <p>Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” events (infrastructural breakdowns or blackouts etc.).</p>
        </SubSection>
        <SubSection>
          <h3>Service reselling</h3>
          <p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.</p>
        </SubSection>
        <SubSection>
          <h3>Privacy Policy</h3>
          <p>To learn more about the use of their personal data, Users may refer to the privacy policy of this Application.</p>
        </SubSection>
        <SubSection>
          <h3>Intellectual property rights</h3>
          <p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Application are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>
          <p>All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Application are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
        </SubSection>
        <SubSection>
          <h3>Changes to these Terms</h3>
          <p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>
          <p>Such changes will only affect the relationship with the User from the date communicated to Users onwards.</p>
          <p><b>The continued use of the Service will signify the User’s acceptance of the revised Terms.</b> If Users do not wish to be bound by the changes, they must stop using the Service and may terminate the Agreement.</p>
          <p>The applicable previous version will govern the relationship prior to the User&apos;s acceptance. The User can obtain any previous version from the Owner.</p>
          <p>If legally required, the Owner will notify Users in advance of when the modified Terms will take effect.</p>
        </SubSection>
        <SubSection>
          <h3>Assignment of contract</h3>
          <p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account.
            Provisions regarding changes of these Terms will apply accordingly.</p>
          <p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
        </SubSection>
        <SubSection>
          <h3>Contacts</h3>
          <p>All communications relating to the use of this Application must be sent using the contact information stated in this document.</p>
        </SubSection>
        <SubSection>
          <h3>Severability</h3>
          <p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
          <h4>US Users</h4>
          <p>Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent.
              These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter.
              These Terms will be enforced to the fullest extent permitted by law.
          </p>
        </SubSection>
        <SubSection>
          <h3>Governing Law</h3>
          <p>These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.</p>
          <h4>Prevalence of national law</h4>
          <p>However, regardless of the above, if the law of the country that the User is located in provides for higher applicable consumer protection standards, such higher standards shall prevail.</p>
        </SubSection>
        <SubSection>
          <h2>Venue of jurisdiction</h2>
          <p>The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.</p>
        </SubSection>
      </Section>


      <DefinitionsSection>
      <DefinitionDropdown>
        <DropdownHeader onClick={toggleDropdown}>
          <h2 style={{ display: 'flex', alignItems: 'center' }}>
            <FaBookmark />&nbsp; Definitions and legal references
          </h2>
          <span>{isOpen ? '▼' : '▲'}</span>
        </DropdownHeader>
        
        <DropdownContent isOpen={isOpen}>
          <Definition>
            <strong>This Application (or this Application)</strong>
            <p>The property that enables the provision of the Service.</p>
          </Definition>

          <Definition>
            <strong>Agreement</strong>
            <p>Any legally binding or contractual relationship between the Owner and the User, governed by these Terms.</p>
          </Definition>

          <Definition>
            <strong>Business User</strong>
            <p>Any User that does not qualify as a Consumer.</p>
          </Definition>

          <Definition>
            <strong>Digital Product</strong>
            <p>Is a Product that consists of:</p>
            <ul>
              <li>content produced and supplied in digital form; and/or</li>
              <li>a service that allows for the creation, processing, storing or accessing data in a digital form or the sharing or any other form of interaction with digital data uploaded or created by the User or any other user of this Application.</li>
            </ul>
          </Definition>

          <Definition>
            <strong>European (or Europe)</strong>
            <p>Applies where a User, regardless of nationality, is in the EU.</p>
          </Definition>

          <Definition>
            <strong>Owner (or We)</strong>
            <p>Indicates the natural person(s) or legal entity that provides this Application and/or the Service to Users.</p>
          </Definition>

          <Definition>
            <strong>Service</strong>
            <p>The service provided by this Application as described in these Terms and on this Application.</p>
          </Definition>

          <Definition>
            <strong>Terms</strong>
            <p>All provisions applicable to the use of this Application and/or the Service as described in this document, including any other related documents or agreements, and as updated from time to time.</p>
          </Definition>

          <Definition>
            <strong>User (or You)</strong>
            <p>Indicates any natural person or legal entity using this Application.</p>
          </Definition>

          <Definition>
            <strong>Consumer</strong>
            <p>Consumer is any User qualifying as such under applicable law.</p>
          </Definition>
        </DropdownContent>
      </DefinitionDropdown>
    </DefinitionsSection>
      </TextContainer>
    </div>
  )
    // return (
    //     <div style={{ overflowX: 'hidden', background: 'white' }}>
    //         <NonMainNavbar loggedInUser={loggedInUser} />
    //         <TextContainer>
    //             <h1>Terms of Service</h1>
    //             <LastUpdated>Last Updated: December 17th, 2024</LastUpdated>

    //             <Section>
    //                 <h2>1. Agreement to Terms</h2>
    //                 <p>
    //                     By accessing and using Muugen Invest&apos;s website and investment platform (the &quot;Platform&quot;), 
    //                     you agree to be bound by these Terms of Service (&quot;Terms&quot;). If you do not agree to these Terms, 
    //                     do not use the Platform.
    //                 </p>
    //             </Section>

    //             <Section>
    //                 <h2>2. Eligibility</h2>
    //                 <SubSection>
    //                     <h3>2.1 Investment Eligibility</h3>
    //                     <p>
    //                         Our Platform operates under Regulation Crowdfunding (Reg CF). Both accredited and 
    //                         non-accredited investors may invest, subject to the following conditions:
    //                     </p>
    //                     <ImportantNote>
    //                         <p>Annual Investment Limits: Non-accredited investors are limited in how much they can invest 
    //                         across all Reg CF offerings in a 12-month period based on their annual income and net worth:</p>
    //                     </ImportantNote>
    //                     <List>
    //                         <ListItem>
    //                             If either annual income or net worth is less than $107,000, you may invest the greater 
    //                             of $2,500 or 5% of the greater of annual income or net worth
    //                         </ListItem>
    //                         <ListItem>
    //                             If both annual income and net worth are $107,000 or more, you may invest up to 10% 
    //                             of the greater of annual income or net worth
    //                         </ListItem>
    //                         <ListItem>
    //                             The maximum amount you can invest in all Reg CF offerings in a 12-month period is $107,000
    //                         </ListItem>
    //                     </List>
    //                     <List>
    //                         <ListItem>
    //                             You agree to provide accurate information about your income, net worth, and other 
    //                             information required to verify your investment eligibility
    //                         </ListItem>
    //                         <ListItem>
    //                             You understand that we may require verification of your identity and eligibility information
    //                         </ListItem>
    //                         <ListItem>
    //                             You acknowledge that providing false information may result in civil or criminal penalties
    //                         </ListItem>
    //                     </List>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>2.2 Legal Capacity</h3>
    //                     <p>
    //                         You must be at least 18 years old and have the legal capacity to enter into binding contracts. 
    //                         By using the Platform, you represent that you meet these requirements.
    //                     </p>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>3. Investment Terms</h2>
    //                 <SubSection>
    //                     <h3>3.1 Investment Risks and Disclosures</h3>
    //                     <p>As required by Regulation Crowdfunding:</p>
    //                     <List>
    //                         <ListItem>All investments carry risk, including the risk of losing your entire investment</ListItem>
    //                         <ListItem>Past performance is not indicative of future results</ListItem>
    //                         <ListItem>Film investments are particularly speculative and illiquid</ListItem>
    //                         <ListItem>Returns are not guaranteed</ListItem>
    //                         <ListItem>You should only invest what you can afford to lose</ListItem>
    //                     </List>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>3.2 Investment Process</h3>
    //                     <List>
    //                         <ListItem>All investments are made through the Platform</ListItem>
    //                         <ListItem>Minimum investment amounts apply</ListItem>
    //                         <ListItem>Funds must be received by specified deadlines</ListItem>
    //                         <ListItem>Investment confirmations will be provided electronically</ListItem>
    //                         <ListItem>Changes to investment commitments must follow Platform procedures</ListItem>
    //                     </List>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>4. Platform Rules</h2>
    //                 <SubSection>
    //                     <h3>4.1 Account Security</h3>
    //                     <List>
    //                         <ListItem>You are responsible for maintaining account confidentiality</ListItem>
    //                         <ListItem>You must notify us immediately of unauthorized access</ListItem>
    //                         <ListItem>You agree not to share account credentials</ListItem>
    //                         <ListItem>We may suspend accounts for security concerns</ListItem>
    //                     </List>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>4.2 Prohibited Activities</h3>
    //                     <p>You agree not to:</p>
    //                     <List>
    //                         <ListItem>Provide false information</ListItem>
    //                         <ListItem>Circumvent Platform security</ListItem>
    //                         <ListItem>Scrape or copy Platform content</ListItem>
    //                         <ListItem>Share confidential project information</ListItem>
    //                         <ListItem>Harass or harm other users</ListItem>
    //                         <ListItem>Violate applicable laws</ListItem>
    //                     </List>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>5. Film Project Information and Offering Disclosures</h2>
    //                 <SubSection>
    //                     <h3>5.1 Required Disclosures</h3>
    //                     <p>Each film project offering on our Platform includes:</p>
    //                     <List>
    //                         <ListItem>Form C and mandatory SEC disclosures</ListItem>
    //                         <ListItem>Financial statements prepared according to SEC requirements</ListItem>
    //                         <ListItem>Business plan and project description</ListItem>
    //                         <ListItem>Risk factors specific to the film project</ListItem>
    //                         <ListItem>Team background and experience</ListItem>
    //                         <ListItem>Use of proceeds</ListItem>
    //                         <ListItem>Target offering amount and deadline</ListItem>
    //                         <ListItem>Regular progress updates</ListItem>
    //                         <ListItem>Annual reports and other ongoing disclosures</ListItem>
    //                     </List>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>5.2 Investment Cancellation Rights</h3>
    //                     <List>
    //                         <ListItem>
    //                             You may cancel your investment commitment up to 48 hours before the offering deadline
    //                         </ListItem>
    //                         <ListItem>
    //                             If there is a material change to an offering, you will be notified and must reconfirm 
    //                             your investment within 5 business days
    //                         </ListItem>
    //                         <ListItem>
    //                             If you do not reconfirm after a material change, your investment will be canceled
    //                         </ListItem>
    //                     </List>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>6. Fees and Payments</h2>
    //                 <SubSection>
    //                     <h3>6.1 Platform Fees</h3>
    //                     <List>
    //                         <ListItem>Investment facilitation fees apply</ListItem>
    //                         <ListItem>Management fees may be charged</ListItem>
    //                         <ListItem>Payment processing fees may apply</ListItem>
    //                         <ListItem>Fee schedule available on Platform</ListItem>
    //                     </List>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>6.2 Payment Terms</h3>
    //                     <List>
    //                         <ListItem>Payments must be made through approved methods</ListItem>
    //                         <ListItem>Failed payments may forfeit investment rights</ListItem>
    //                         <ListItem>Refund policies are project-specific</ListItem>
    //                         <ListItem>Tax documents will be provided as required</ListItem>
    //                     </List>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>7. Communications</h2>
    //                 <SubSection>
    //                     <h3>7.1 Electronic Communications</h3>
    //                     <p>You consent to receive communications electronically, including:</p>
    //                     <List>
    //                         <ListItem>Investment documents</ListItem>
    //                         <ListItem>Project updates</ListItem>
    //                         <ListItem>Account notifications</ListItem>
    //                         <ListItem>Tax documents</ListItem>
    //                         <ListItem>Legal notices</ListItem>
    //                     </List>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>7.2 Confidentiality</h3>
    //                     <List>
    //                         <ListItem>Project information is confidential</ListItem>
    //                         <ListItem>Investment terms are private</ListItem>
    //                         <ListItem>Non-disclosure agreements may apply</ListItem>
    //                         <ListItem>Sharing restrictions must be followed</ListItem>
    //                     </List>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>8. Disclaimers and Limitations</h2>
    //                 <SubSection>
    //                     <h3>8.1 Platform Disclaimers</h3>
    //                     <List>
    //                         <ListItem>Platform provided as &quot;is&quot;</ListItem>
    //                         <ListItem>No guarantees of availability</ListItem>
    //                         <ListItem>Technical issues may occur</ListItem>
    //                         <ListItem>Investment success not guaranteed</ListItem>
    //                     </List>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>8.2 Limitation of Liability</h3>
    //                     <List>
    //                         <ListItem>Maximum liability limited by law</ListItem>
    //                         <ListItem>No consequential damages</ListItem>
    //                         <ListItem>Investment losses not compensated</ListItem>
    //                         <ListItem>Force majeure exclusions apply</ListItem>
    //                     </List>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>9. Dispute Resolution</h2>
    //                 <SubSection>
    //                     <h3>9.1 Governing Law</h3>
    //                     <p>These Terms are governed by the laws of the State of California and the federal laws of the United States, without regard to conflicts of law principles. You acknowledge that the Securities Act of 1933, the Securities Exchange Act of 1934, and Regulation Crowdfunding (17 CFR § 227) apply to offerings on the Platform.</p>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>9.2 Arbitration</h3>
    //                     <List>
    //                         <ListItem>Disputes resolved by binding arbitration</ListItem>
    //                         <ListItem>Class action waiver applies</ListItem>
    //                         <ListItem>Arbitration location specified</ListItem>
    //                         <ListItem>Limited appeal rights</ListItem>
    //                     </List>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>10. Changes to Terms</h2>
    //                 <p>
    //                     We may modify these Terms at any time. Continued use constitutes acceptance of changes.
    //                 </p>
    //             </Section>

    //             <Section>
    //                 <h2>11. Termination</h2>
    //                 <SubSection>
    //                     <h3>11.1 Account Termination</h3>
    //                     <p>We may terminate accounts for:</p>
    //                     <List>
    //                         <ListItem>Terms violations</ListItem>
    //                         <ListItem>Fraudulent activity</ListItem>
    //                         <ListItem>Legal requirements</ListItem>
    //                         <ListItem>Platform protection</ListItem>
    //                     </List>
    //                 </SubSection>

    //                 <SubSection>
    //                     <h3>11.2 Effect of Termination</h3>
    //                     <List>
    //                         <ListItem>Existing investments maintained</ListItem>
    //                         <ListItem>Access rights limited</ListItem>
    //                         <ListItem>Confidentiality continues</ListItem>
    //                         <ListItem>Legal obligations survive</ListItem>
    //                     </List>
    //                 </SubSection>
    //             </Section>

    //             <Section>
    //                 <h2>12. Contact Information</h2>
    //                 <p>For questions about these Terms:</p>
    //                 <ContactInfo>
    //                     <p>Muugen Invest</p>
    //                     <p>Email: support@muugen.com</p>
    //                 </ContactInfo>
    //             </Section>

    //             <Section>
    //                 <h2>13. Severability</h2>
    //                 <p>
    //                     If any provision is found unenforceable, remaining Terms remain in effect.
    //                 </p>
    //             </Section>
    //         </TextContainer>
    //     </div>
    // )
}

export default TermsOfService

// Terms and Conditions of www.invest.muugen.com

// Introduction
// These Terms govern

// the use of this Application, and,
// any other related Agreement or legal relationship with the Owner

// in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.

// The User must read this document carefully.



// This Application is provided by:


// Muugen Invest

// Owner contact email: support@muugen.com



// "This Application" refers to

// this website, including its subdomains and any other website through which the Owner makes its Service available;



// applications for mobile, tablet and other smart device systems;



// the Application Program Interfaces (API);



// the Service;



// any applications, sample and content files, source code, scripts, instruction sets or software included as part of the Service, as well as any related documentation;




// What the User should know at a glance

// Usage of this Application and the Service is age restricted: to access and use this Application and its Service the User must be an adult under applicable law.



// Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.




// TERMS OF USEUnless otherwise specified, the terms of use detailed in this section apply generally when using this Application.

// Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.

// By using this Application, Users confirm to meet the following requirements:

// There are no restrictions for Users in terms of being Consumers or Business Users;



// Users must be recognized as adult by applicable law;


// Account registration
// To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.
// Failure to do so will cause unavailability of the Service.

// Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Application.

// By registering, Users agree to be fully responsible for all activities that occur under their username and password.
// Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.
// Account termination
// Users can terminate their account and stop using the Service subject to the conditions and according to the procedures outlined in the relevant section of this Application.
// Account suspension and deletion
// The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts that it deems inappropriate, offensive or in violation of these Terms.

// The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.

// The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.
// Content on this Application
// Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.

// The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.
// In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.
// Rights regarding content on this Application - All rights reserved
// The Owner holds and reserves all intellectual property rights for any such content.

// Users may not, therefore, use such content in any way that is not necessary or implicit in the proper use of the Service.
// In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Application, nor allow any third party to do so through the User or their device, even without the User's knowledge.

// Where explicitly stated on this Application, the User may download, copy and/or share some content available through this Application for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.

// Any applicable statutory limitation or exception to copyright shall stay unaffected.
// Content provided by Users
// The Owner allows Users to upload, share or provide their own content to this Application.

// By providing content to this Application, Users confirm that they are legally allowed to do so and that they are not infringing any statutory provisions and/or third-party rights.
// Rights regarding content provided by Users
// Users acknowledge and accept that by providing their own content on this Application they grant the Owner a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of this Application as contractually required.

// To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to this Application.
// Users acknowledge, accept and confirm that all content they provide through this Application is provided subject to the same general conditions set forth for content on this Application.
// Liability for provided content
// Users are solely liable for any content they upload, post, share, or provide through this Application. Users acknowledge and accept that the Owner does not filter or moderate such content.

// However, the Owner reserves the right to remove, delete or block such content at its own discretion and, without prior notice, to deny the uploading User access to this Application:


// upon becoming aware of any (alleged) violation of these Terms, any third-party rights, or applicable law, based on such content;
// if a notice of infringement of intellectual property rights is received;
// if a notice of violation of a third party’s privacy, including their intimate privacy, is received;
// upon order of a public authority; or
// where the Owner is made aware that the content, while being accessible via this Application, may represent a risk for Users, third parties and/or the availability of the Service.



// The removal, deletion or blocking of content shall not entitle Users that have provided such content or that are liable for it, to any claims for compensation, damages or reimbursement.

// Users agree to hold the Owner harmless from and against any claim asserted and/or damage suffered due to content they provided to or provided through this Application.
// Access to external resources
// Through this Application Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.

// Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.
// Acceptable use
// This Application and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.

// Users are solely responsible for making sure that their use of this Application and/or the Service violates no applicable law, regulations or third-party rights.
// Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Application or the Service, terminating contracts, reporting any misconduct performed through this Application or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:


// violate laws, regulations and/or these Terms;
// infringe any third-party rights;
// considerably impair the Owner’s legitimate interests;
// offend the Owner or any third party.


// API usage terms
// Users may access their data relating to this Application via the Application Program Interface (API). Any use of the API, including use of the API through a third-party product/service that accesses this Application, is bound by these Terms and, in addition, by the following specific terms:


// the User expressly understands and agrees that the Owner bears no responsibility and shall not be held liable for any damages or losses resulting from the User’s use of the API or their use of any third-party products/services that access data through the API.


// Regulation Crowdfunding Portal Specific Terms

// Funding Portal Activities and Limitations

// Permitted Activities
// This Application operates as a funding portal registered with the SEC and FINRA to facilitate the offer and sale of securities under Regulation Crowdfunding. As a funding portal, we:

// 1. Allow companies to offer and sell securities through our platform
// 2. Provide communication channels for potential investors to communicate with issuers
// 3. Facilitate the investment commitment process
// 4. Direct the transmission of funds between investors and issuers
// 5. Provide educational materials about securities offerings

// Regulatory Limitations
// As a funding portal, we are prohibited from:

// 1. Offering investment advice or recommendations
// 2. Soliciting purchases, sales, or offers to buy securities offered on our platform
// 3. Compensating employees or agents for soliciting securities offerings
// 4. Holding, managing, possessing, or handling investor funds or securities
// 5. Having a financial interest in any issuer using our services

// Investor Requirements and Limitations

// Investment Limits
// Users acknowledge that there are limits on how much they can invest during any 12-month period through all Regulation Crowdfunding offerings. These limits depend on your annual income and net worth:

// 1. If either your annual income or net worth is less than $124,000, you can invest the greater of:
//    - $2,500; or
//    - 5% of the lesser of your annual income or net worth

// 2. If both your annual income and net worth are equal to or more than $124,000, you can invest up to 10% of the lesser of your annual income or net worth

// 3. During the 12-month period, your total investments in all Regulation Crowdfunding offerings cannot exceed $124,000

// Self-Certification
// Users must self-certify their income, net worth, and aggregate Regulation Crowdfunding investments for the past 12 months before making an investment commitment.

// Issuer Requirements

// Disclosure Requirements
// Issuers using our platform must:

// 1. File specific disclosure documents with the SEC
// 2. Provide complete and accurate information about their offering
// 3. Update investors about material changes
// 4. Comply with ongoing reporting requirements

// Offering Limitations
// Issuers may raise a maximum of $5 million through Regulation Crowdfunding offerings in a 12-month period.

// Investment Process

// Investment Commitments
// 1. Users may make investment commitments only after reviewing issuer disclosures
// 2. Users have the right to cancel their investment commitment for any reason until 48 hours prior to the offering deadline
// 3. If there is a material change to an offering, Users will be notified and must reconfirm their investment within 5 business days

// Early Completion
// If an offering reaches its target amount prior to the deadline, the issuer may close the offering early if:
// 1. The offering has been open for a minimum of 21 days
// 2. Investors are given notice of the new deadline
// 3. Investors maintain their right to cancel until 48 hours prior to the new deadline

// Educational Materials

// Required Review
// Users must review our educational materials and demonstrate their understanding of:
// 1. The process for investing in a Regulation Crowdfunding offering
// 2. The risks associated with investing in securities offered under Regulation Crowdfunding
// 3. Investment limitations
// 4. Resale restrictions
// 5. Investor's rights to cancel an investment commitment

// Risk Disclosures

// Investment Risks
// Users acknowledge that investments in startups and early-stage companies through Regulation Crowdfunding:
// 1. Are highly speculative and involve a high degree of risk
// 2. Are illiquid with limited resale opportunities
// 3. May result in a complete loss of investment
// 4. Should only represent a small portion of an investor's portfolio

// Communications

// Platform Communications
// 1. All communications regarding offerings must occur through the platform's communication channels
// 2. Issuers must respond to questions and comments in the communication channels
// 3. Promotional activities must comply with SEC regulations regarding tombstone ads

// Compliance

// Anti-Money Laundering
// Users agree to provide any information necessary for the platform to comply with anti-money laundering regulations and verify investor identity.

// Bad Actor Disqualification
// Issuers must certify that they are not subject to "bad actor" disqualification under Securities regulations.

// Service Providers

// Third-Party Service Providers
// The platform may engage qualified third-party service providers to:
// 1. Verify investor identity
// 2. Perform background checks on issuers
// 3. Process payments
// 4. Provide escrow services
// 5. Maintain records

// Records Retention

// Record Keeping
// The platform will maintain records of all offerings and transactions for the period required by SEC regulations, including:
// 1. Records of investors
// 2. Records of issuers
// 3. Communications
// 4. Investment commitments
// 5. Transactions


// Liability and indemnificationUnless otherwise explicitly stated or agreed with Users, the Owner’s liability for damages in connection with the execution of the Agreement shall be excluded, limited and/or reduced to the maximum extent permitted by applicable law.
// Indemnification
// The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees harmless from and against any claim or demand ⁠— including but not limited to lawyer's fees and costs ⁠— made by any third party due to or in relation with any culpable violation of these Terms, third-party rights or statutory provisions connected to the use of the Service by the User or its affiliates, officers, directors, agents, co-branders, partners and employees to the extent allowed by applicable law.
// The above also applies to any claims exercised by third parties (including but not limited to the Owner’s clients or customers) against the Owner related to Digital Products provided by the User such as, for instance, conformity claims.
// Limitation of liability
// Unless otherwise explicitly stated and without prejudice to applicable law, Users shall have no right to claim damages against the Owner (or any natural or legal person acting on its behalf).

// This does not apply to damages to life, health or physical integrity, damages resulting from the breach of material contractual obligations such as any obligation strictly necessary to achieve the purpose of the contract, and/or damages resulting from intent or gross negligence, as long as this Application has been appropriately and correctly used by the User.

// Unless damages have been caused by way of intent or gross negligence, or they affect life, health or physical integrity, the Owner shall only be liable to the extent of typical and foreseeable damages at the moment the contract was entered into.
// Australian UsersLimitation of liability
// Nothing in these Terms excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act 2010 (Cth) or any similar state and territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms, is limited, at the Owner’s sole discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.
// US UsersDisclaimer of Warranties
// This Application is provided strictly on an “as is” and “as available” basis.  Use of the Service is at Users’ own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by the User from the Owner or through the Service will create any warranty not expressly stated herein.

// Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users’ requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at Users' own risk and Users shall be solely responsible for any damage to Users’ computer system or mobile device or loss of data that results from such download or Users’ use of the Service.

// The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.

// The Service may become inaccessible or it may not function properly with Users’ web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.

// Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.
// Limitations of liability
// To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for:


// any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and
// any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;
// any errors, mistakes, or inaccuracies of content;
// personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;
// any unauthorized access to or use of the Owner’s secure servers and/or any and all personal information stored therein;
// any interruption or cessation of transmission to or from the Service;
// any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;
// any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or
// the defamatory, offensive, or illegal conduct of any User or third party.



// In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.

// This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if the User has been advised of the possibility of such damage.

// Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to the User.  The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction.  The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.
// Indemnification
// The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from


// User’s use of and access to the Service, including any data or content transmitted or received by User;
// User’s violation of these terms, including, but not limited to, User’s breach of any of the representations and warranties set forth in these terms;
// User’s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;
// User’s violation of any statutory law, rule, or regulation;
// any content that is submitted from User’s account, including third party access with User’s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;
// User’s wilful misconduct; or
// statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.



// Common provisionsNo Waiver
// The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision.  No waiver shall be considered a further or continuing waiver of such term or any other term.
// Service interruption
// To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.

// Within the limits of law, the Owner may also decide to suspend or discontinue the Service altogether. If the Service is discontinued, the Owner will cooperate with Users to enable them to withdraw personal data or information and will respect Users' rights relating to continued product use and/or compensation, as provided for by applicable law.

// Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” events (infrastructural breakdowns or blackouts etc.).
// Service reselling
// Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.
// Privacy policy
// To learn more about the use of their personal data, Users may refer to the privacy policy of this Application.
// Intellectual property rights
// Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Application are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.

// All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Application are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.
// Changes to these Terms
// The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.

// Such changes will only affect the relationship with the User from the date communicated to Users onwards.

// The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service and may terminate the Agreement.

// The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.
// If legally required, the Owner will notify Users in advance of when the modified Terms will take effect.
// Assignment of contract
// The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account.
// Provisions regarding changes of these Terms will apply accordingly.

// Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.
// Contacts
// All communications relating to the use of this Application must be sent using the contact information stated in this document.
// Severability
// Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.
// US Users
// Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent.
// These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter.
// These Terms will be enforced to the fullest extent permitted by law.

// Without prejudice to the above, the nullity, invalidity or impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.
// Governing law
// These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.
// Prevalence of national law
// However, regardless of the above, if the law of the country that the User is located in provides for higher applicable consumer protection standards, such higher standards shall prevail.


// # Terms of Service

// Last Updated: [Date]

// ## 1. Agreement to Terms

// By accessing and using Muugen Invest's website and investment platform (the "Platform"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use the Platform.

// ## 2. Eligibility

// ### 2.1 Investment Eligibility
// Our Platform operates under Regulation Crowdfunding (Reg CF). Both accredited and non-accredited investors may invest, subject to the following conditions:

// - Annual Investment Limits: Non-accredited investors are limited in how much they can invest across all Reg CF offerings in a 12-month period based on their annual income and net worth:
//   - If either annual income or net worth is less than $107,000, you may invest the greater of $2,200 or 5% of the greater of annual income or net worth
//   - If both annual income and net worth are $107,000 or more, you may invest up to 10% of the greater of annual income or net worth
//   - The maximum amount you can invest in all Reg CF offerings in a 12-month period is $107,000

// - You agree to provide accurate information about your income, net worth, and other information required to verify your investment eligibility
// - You understand that we may require verification of your identity and eligibility information
// - You acknowledge that providing false information may result in civil or criminal penalties

// ### 2.2 Legal Capacity
// You must be at least 18 years old and have the legal capacity to enter into binding contracts. By using the Platform, you represent that you meet these requirements.

// ## 3. Investment Terms

// ### 3.1 Investment Risks and Disclosures

// As required by Regulation Crowdfunding:
// - All investments carry risk, including the risk of losing your entire investment
// - Past performance is not indicative of future results
// - Film investments are particularly speculative and illiquid
// - Returns are not guaranteed
// - You should only invest what you can afford to lose

// ### 3.2 Investment Process
// - All investments are made through the Platform
// - Minimum investment amounts apply
// - Funds must be received by specified deadlines
// - Investment confirmations will be provided electronically
// - Changes to investment commitments must follow Platform procedures

// ## 4. Platform Rules

// ### 4.1 Account Security
// - You are responsible for maintaining account confidentiality
// - You must notify us immediately of unauthorized access
// - You agree not to share account credentials
// - We may suspend accounts for security concerns

// ### 4.2 Prohibited Activities
// You agree not to:
// - Provide false information
// - Circumvent Platform security
// - Scrape or copy Platform content
// - Share confidential project information
// - Harass or harm other users
// - Violate applicable laws

// ## 5. Film Project Information and Offering Disclosures

// ### 5.1 Required Disclosures
// Each film project offering on our Platform includes:
// - Form C and mandatory SEC disclosures
// - Financial statements prepared according to SEC requirements
// - Business plan and project description
// - Risk factors specific to the film project
// - Team background and experience
// - Use of proceeds
// - Target offering amount and deadline
// - Regular progress updates
// - Annual reports and other ongoing disclosures

// ### 5.2 Investment Cancellation Rights
// - You may cancel your investment commitment up to 48 hours before the offering deadline
// - If there is a material change to an offering, you will be notified and must reconfirm your investment within 5 business days
// - If you do not reconfirm after a material change, your investment will be canceled

// ### 5.3 Project Information

// ### 5.1 Project Details
// - Project information is provided by film production companies
// - We do not guarantee accuracy of project information
// - Due diligence materials are subject to confidentiality
// - Additional verification may be required

// ### 5.2 Intellectual Property
// - Film projects retain all intellectual property rights
// - Investment does not grant creative control rights
// - Confidential information must be protected
// - Marketing materials require approval

// ## 6. Fees and Payments

// ### 6.1 Platform Fees
// - Investment facilitation fees apply
// - Management fees may be charged
// - Payment processing fees may apply
// - Fee schedule available on Platform

// ### 6.2 Payment Terms
// - Payments must be made through approved methods
// - Failed payments may forfeit investment rights
// - Refund policies are project-specific
// - Tax documents will be provided as required

// ## 7. Communications

// ### 7.1 Electronic Communications
// You consent to receive communications electronically, including:
// - Investment documents
// - Project updates
// - Account notifications
// - Tax documents
// - Legal notices

// ### 7.2 Confidentiality
// - Project information is confidential
// - Investment terms are private
// - Non-disclosure agreements may apply
// - Sharing restrictions must be followed

// ## 8. Disclaimers and Limitations

// ### 8.1 Platform Disclaimers
// - Platform provided "as is"
// - No guarantees of availability
// - Technical issues may occur
// - Investment success not guaranteed

// ### 8.2 Limitation of Liability
// - Maximum liability limited by law
// - No consequential damages
// - Investment losses not compensated
// - Force majeure exclusions apply

// ## 9. Dispute Resolution

// ### 9.1 Governing Law
// These Terms are governed by [Jurisdiction] law.

// ### 9.2 Arbitration
// - Disputes resolved by binding arbitration
// - Class action waiver applies
// - Arbitration location specified
// - Limited appeal rights

// ## 10. Changes to Terms

// We may modify these Terms at any time. Continued use constitutes acceptance of changes.

// ## 11. Termination

// ### 11.1 Account Termination
// We may terminate accounts for:
// - Terms violations
// - Fraudulent activity
// - Legal requirements
// - Platform protection

// ### 11.2 Effect of Termination
// - Existing investments maintained
// - Access rights limited
// - Confidentiality continues
// - Legal obligations survive

// ## 12. Contact Information

// For questions about these Terms:

// Muugen Invest
// Email: support@muugen.com

// ## 13. Severability

// If any provision is found unenforceable, remaining Terms remain in effect.