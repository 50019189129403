import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../authContext'
import axios from 'axios'
import { API_URL } from '../../config'
import NonMainNav from '../NonMainNav'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useQueryClient } from '@tanstack/react-query'

import {
    SignupError,
    SignupField,
    InputWrapper,
    InputLabel,
    CheckboxText,
    CheckboxLink,
    PrivacyPolicyText,
    // PasswordStrengthBar,
    // PasswordStrengthText,
    ErrorListWrapper,
    ErrorList,
    ErrorListItem
} from './SignupElements'

import { 
    Wrapper, 
    LoginHeader,
    LoginWrapper, 
    LoginButton, 
    PasswordWrapper,
    PasswordInput,
    EyeIcon,
} from '../Login/LoginElements'

function SignupComponent() {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [passwordError, setPasswordError] = useState('')
    const [reenterPassword, setReenterPassword] = useState('')
    const [showReenterPassword, setShowReenterPassword] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [signupError, setSignupError] = useState(false)
    // const [passwordStrength, setPasswordStrength] = useState('')
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false
    })
    const [formErrors, setFormErrors] = useState({
        username: '',
        email: '',
        password: '',
        reenterPassword: '',
    })

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { loggedInUser } = useContext(AuthContext)

    const handleTermsClick = () => {
        navigate('/legal/terms')
    }

    const handlePrivacyClick = () => {
        navigate('/legal/privacy-policy')
    }

    // const checkPasswordStrengthColorCode = (password) => {
    //     if (
    //         password.length < 8 ||
    //         !/[a-z]/.test(password) ||
    //         !/[A-Z]/.test(password) ||
    //         !/[0-9]/.test(password) ||
    //         !/[\W_]/.test(password)
    //     ) return 'too weak'
    
    //     let score = 0
    
    //     if (password.length >= 12) score++
    //     if (password.replace(/[^0-9]/g, '').length > 1) score++
    //     if (password.replace(/[^A-Z]/g, '').length > 1) score++
    //     if (password.replace(/[\w]/g, '').length > 1) score++
    
    //     if (score < 2) return 'okay'
    //     return 'strong'
    // }

    const handlePasswordChange = (event) => {
        const { name, value } = event.target
        setPassword(value)
        // setPasswordStrength(checkPasswordStrengthColorCode(value))
        setFormErrors({ ...formErrors, [name]: '' })
        
        // Update password requirements
        setPasswordRequirements({
            minLength: value.length >= 8,
            lowercase: /[a-z]/.test(value),
            uppercase: /[A-Z]/.test(value),
            number: /[0-9]/.test(value),
            specialChar: /[\W_]/.test(value)
        })
        
        if (isFormSubmitted && value === reenterPassword) {
            setPasswordError('')
        }
    }

    const handleReenterPasswordChange = (event) => {
        const { name, value } = event.target
        setReenterPassword(value)
        setFormErrors({ ...formErrors, [name]: '' })
        if (isFormSubmitted && value === password) {
            setPasswordError('')
        }
    }

    const checkPasswordStrength = (password) => {
        if (password.length < 8) return 'Password must be at least 8 characters long.'
        if (!/[a-z]/.test(password)) return 'Password must contain at least one lowercase letter.'
        if (!/[A-Z]/.test(password)) return 'Password must contain at least one uppercase letter.'
        if (!/[0-9]/.test(password)) return 'Password must contain at least one number.'
        if (!/[\W_]/.test(password)) return 'Password must contain at least one special character.'
        return ''
    }    

    const handleInputChange = (event, setInfo) => {
        const { name, value } = event.target

        setFormErrors({ ...formErrors, [name]: '' })
        if (name === 'username') {
            setUsername(value.toLowerCase())
        } else if (name === 'email') {
            setEmail(value)
        }
        setInfo(value)
    }

    const handleSignup = async e => {
        e.preventDefault()
        setIsFormSubmitted(true)

        let errors = {}

        if (!username.trim()) errors.username = 'Username is required.'
        if (!email.trim()) errors.email = 'Email is required.'
        if (!password.trim()) errors.password = 'Password is required.'
        if (!reenterPassword.trim()) errors.reenterPassword = 'Please re-enter your password.'

        if (password !== reenterPassword) {
            setPasswordError('Passwords do not match')
            return
        } else {
            setPasswordError('')
        }

        setFormErrors(errors)

        if (Object.keys(errors).length > 0) {
            return
        }

        const passwordStrengthMessage = checkPasswordStrength(password)
        if (passwordStrengthMessage) {
            setPasswordError(passwordStrengthMessage)
            return
        }

        const payload = {
            username,
            email,
            password,
        }

        try {
            const response = await axios.post(`${API_URL}/signup`, payload, { withCredentials: true })

            if (response.data) {
                queryClient.invalidateQueries(['user'])
                navigate('/')
            }

            if (response.data.error) {
                setSignupError(response.data.error)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <NonMainNav loggedInUser={loggedInUser} />
            <Wrapper>
                <LoginWrapper>
                    <LoginHeader>Muugen Invest</LoginHeader>
                    <InputWrapper>
                        <InputLabel style={formErrors.username || signupError ? { color: 'red' } : {}}>Username</InputLabel>
                        <SignupField 
                            style={formErrors.username || signupError ? { borderColor: 'red' } : {}} 
                            onChange={event => handleInputChange(event, setUsername)} 
                            type='text' 
                            name='username'
                            value={username}
                            placeholder='Create your username.'
                        />
                        {formErrors.username && <SignupError>{formErrors.username}</SignupError>}
                        {signupError && <SignupError>{signupError}</SignupError>}
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel style={formErrors.email ? { color: 'red' } : {}}>Email Address</InputLabel>
                        <SignupField 
                            style={formErrors.email ? { borderColor: 'red' } : {}} 
                            onChange={event => handleInputChange(event, setEmail)} 
                            type='text' 
                            name='email'
                            value={email}
                            placeholder='Enter your email address.'
                        />
                        {formErrors.email && <SignupError>{formErrors.email}</SignupError>}
                    </InputWrapper>
                    <PasswordWrapper style={{ marginTop: 5 }} >
                        <InputLabel style={passwordError || formErrors.password ? { color: 'red' } : {}}>Password</InputLabel>
                        <PasswordInput 
                            style={passwordError || formErrors.password ? { borderColor: 'red' } : {}} 
                            name='password'
                            onChange={event => handlePasswordChange(event)}  
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Create a password' 
                        />
                        <EyeIcon style={{ marginTop: 5 }} onClick={() => setShowPassword(!showPassword)}>
                            {!showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                        </EyeIcon>
                        {formErrors.password && <SignupError>{formErrors.password}</SignupError>}
                        {/* {password && 
                            <div>
                                <PasswordStrengthText strength={passwordStrength}>{passwordStrength}</PasswordStrengthText>
                                <PasswordStrengthBar strength={passwordStrength} />
                            </div>
                        } */}
                    </PasswordWrapper>
                    <PasswordWrapper style={{ marginTop: 5 }} >
                        <InputLabel style={passwordError || formErrors.reenterPassword ? { color: 'red' } : {}}>Reenter Password</InputLabel>   
                        <PasswordInput 
                            style={passwordError || formErrors.reenterPassword ? { borderColor: 'red' } : {}} 
                            onChange={event => handleReenterPasswordChange(event)} 
                            name='reenterPassword'
                            type={showReenterPassword ? 'text' : 'password'}
                            placeholder='Confirm your password' 
                        />
                        <EyeIcon style={{ marginTop: 5 }} onClick={() => setShowReenterPassword(!showReenterPassword)}>
                            {!showReenterPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                        </EyeIcon>
                        {formErrors.reenterPassword && <SignupError>{formErrors.reenterPassword}</SignupError>}
                    </PasswordWrapper>
                    {passwordError && <SignupError>{passwordError}</SignupError>}
                    
                    <ErrorListWrapper>
                        <ErrorList>
                            <ErrorListItem isMet={passwordRequirements.minLength}>Password must be at least 8 characters long.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.lowercase}>Password must contain at least one lowercase letter.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.uppercase}>Password must contain at least one uppercase letter.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.number}>Password must contain at least one number.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.specialChar}>Password must contain at least one special character.</ErrorListItem>
                        </ErrorList>
                    </ErrorListWrapper>

                    <PrivacyPolicyText>By clicking sign up you are agreeing to our <CheckboxLink onClick={handleTermsClick}>Terms Of Service</CheckboxLink> and <CheckboxLink onClick={handlePrivacyClick} >Privacy Policy</CheckboxLink></PrivacyPolicyText>
                    <LoginButton onClick={handleSignup}>Sign Up</LoginButton>
                </LoginWrapper>
                <LoginWrapper>
                    <CheckboxText>Have an account? <CheckboxLink onClick={() => navigate('/login')}>Log in</CheckboxLink> instead.</CheckboxText>
                </LoginWrapper>
            </Wrapper>
        </div>
    )
}

export default SignupComponent