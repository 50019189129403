
import styled from 'styled-components';

export const TextContainer = styled.div`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
  color: #333;
  font-family: system-ui, -apple-system, sans-serif;
  line-height: 1.6;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #1a1a1a;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #eaeaea;
    color: #2a2a2a;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 1.5rem 0 1rem;
    color: #3a3a3a;
  }

  p {
    margin: 1rem 0;
    font-size: 1rem;
  }

  ul, ol {
    margin: 1rem 0;
    padding-left: 2rem;

    li {
      margin: 0.5rem 0;
      padding-left: 0.5rem;
    }
  }

  a {
    color: #0066cc;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

export const Section = styled.section`
  margin: 2rem 0;
`

export const SubSection = styled.div`
  margin: 1.5rem 0;
  padding-left: 1rem;
`

export const List = styled.ul`
  list-style-type: ${props => props.type || 'disc'};
  margin: 1rem 0;
  padding-left: 2rem;
`

export const ListItem = styled.li`
  margin: 0.5rem 0;
  line-height: 1.5;
`

export const ContactInfo = styled.div`
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  
  p {
    margin: 0.5rem 0;
  }
`

export const LastUpdated = styled.p`
  color: #666;
  font-style: italic;
  margin-bottom: 2rem;
`

export const ImportantNote = styled.div`
  background-color: #fff8f8;
  border-left: 4px solid #ff4444;
  padding: 1rem;
  margin: 1rem 0;
  
  p {
    margin: 0;
    color: #333;
  }
`


export const DefinitionsSection = styled.div`
  margin-top: 2rem;
  border-top: 2px solid #eaeaea;
`

export const DefinitionDropdown = styled.div`
  margin: 1rem 0;
`

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background-color: #e9ecef;
  }

  h2 {
    margin: 0;
    border: none;
  }
`

export const DropdownContent = styled.div`
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-top: none;
  border-radius: 0 0 4px 4px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`

export const Definition = styled.div`
  margin: 1rem 0;
  
  strong {
    display: block;
    margin-bottom: 0.5rem;
  }
`