import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchContext from '../SearchContext'
import { PrimaryColor } from '../../../colors'
import LoadingComponent from '../../LoadingAsset'

import FilmCard from '../DiscoverFilmCard'

import { 
    DiscoverBodyWrapper,
    DiscoverTitle, 
    DiscoverCardsWrapper,
    DiscoverProjectsWrapper,
    NoProjects,
} from '../DiscoverElements'

import Pagination from '../Pagination'

const DiscoverBody = ({ 
    searchedProjects,
    totalProjects
}) => {
    // const [discoverFilter, setDiscoverFilter] = useState('')
    const navigate = useNavigate()
    
    const { isLoading, isError, page, setPage, totalPages } = useContext(SearchContext)

    if (isLoading) {
        return (
            <div style={{ height: 200, }}>
                <LoadingComponent />
            </div>
        )
    }

    if (isError) {
        return (
            <DiscoverBodyWrapper>
                <NoProjects >An error occurred while filtering/searching for projects. Please refresh the page.</NoProjects>
            </DiscoverBodyWrapper>
        )
    }

    const handleFilmClick = (projectId) => {
        navigate(`/project-profile/${projectId}`)
    }

    let ProjectsMap

    if (searchedProjects.length > 0) {
        ProjectsMap = searchedProjects.map((project) => {
            return (
                <FilmCard 
                    key={project?._id}
                    image={project?.coverImageUrl}
                    videoEmbed={project?.mainVideoEmbed}
                    title={project?.projectName}
                    logline={project?.logline}
                    artist={project?.artist}
                    projectId={project?._id}
                    currentFunds={project?.currentFunds}
                    targetFundAmount={project?.targetFundAmount}
                    totalExpressedInterest={project?.totalExpressedInterest}
                    expressedInterestBackers={project?.expressedInterestBackers}
                    investors={project?.investors}
                    projectFundingType={project?.projectFundingType}
                    projectStatus ={project?.projectStatus}
                    onClick={() => handleFilmClick(project?._id)}
                />
            )
        })
    }

    const handlePageChange = (newPage) => {
        setPage(newPage)
    }

    return (
        <DiscoverBodyWrapper>
                <DiscoverTitle>Discover <div style={{ color: PrimaryColor }} >{totalProjects}</div> Films</DiscoverTitle>
                <DiscoverCardsWrapper>
                    <DiscoverProjectsWrapper>
                        {ProjectsMap}
                    </DiscoverProjectsWrapper>
                </DiscoverCardsWrapper>
                {searchedProjects.length === 0 && <NoProjects>There are currently no projects that fit this description.</NoProjects>}
                {searchedProjects.length !== 0 &&
                    <Pagination 
                        currentPage={page}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                }
        </DiscoverBodyWrapper>
    )
}

export default DiscoverBody