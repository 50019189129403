import React, { useState, useRef, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { 
    Wrapper,
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body,
} from '../ProjectProfile/Modal/EditModalElements'

import {
    ButtonWrapper,
    ConfirmButton,
    CancelButton,
    AdminInput,
    PasswordInput,
    RoleSelect,
    ImagePreview, // Assuming you create this styled component for image preview
    DropzoneWrapper // Assuming you create this styled component for the dropzone
} from './AdminElements'

import { addAdmin } from './AdminApi'
import { IoClose } from 'react-icons/io5'

const AdminModal = ({ setShowModal }) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [role, setRole] = useState('Super Admin')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [image, setImage] = useState(null)

    const modalRef = useRef(null)

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]
        setImage(Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*'
    })

    const handleConfirm = () => {
        if (password !== confirmPassword) {
            alert('Passwords do not match.')
            return
        }
    
        const formData = new FormData()
        formData.append('firstName', firstName)
        formData.append('lastName', lastName)
        formData.append('role', role)
        formData.append('email', email)
        formData.append('password', password)
        if (image) {
            formData.append('image', image)
        }
    
        addAdmin(formData).then(() => {
            setShowModal(false)
        }).catch((err) => {
            console.error(err)
            alert('Error adding admin')
        })
    }
    
    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''
            setShowModal(false);
        }
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                <Modal>
                    <Header>
                        <HeaderText>Add Admin</HeaderText>
                        <IoClose style={{ cursor: 'pointer' }} size={25} onClick={() => setShowModal(false)} />
                    </Header>
                    <Body style={{ padding: 10 }}>
                        <AdminInput placeholder='First Name' onChange={e => setFirstName(e.target.value)} />
                        <AdminInput placeholder='Last Name' onChange={e => setLastName(e.target.value)} />
                        <AdminInput placeholder='Email' onChange={e => setEmail(e.target.value)}/>
                        <PasswordInput placeholder='Password' type='password' onChange={e => setPassword(e.target.value)}/>
                        <PasswordInput placeholder='Confirm Password' type='password' onChange={e => setConfirmPassword(e.target.value)} />
                        <RoleSelect onChange={e => setRole(e.target.value)}>
                            <option value='Super Admin'>Super Admin</option>
                            <option value='Admin'>Admin</option>
                            <option value='Artist Manager'>Artist Manager</option>
                        </RoleSelect>
                        <DropzoneWrapper {...getRootProps()}>
                            <input {...getInputProps()} />
                            {image ? (
                                <ImagePreview src={image.preview} alt="Admin Image" />
                            ) : (
                                <p>Drag &apos;n&apos; drop an image here, or click to select one</p>
                            )}
                        </DropzoneWrapper>
                        <ButtonWrapper>
                            <CancelButton onClick={() => setShowModal(false)}>Cancel</CancelButton>
                            <ConfirmButton onClick={handleConfirm} >Confirm</ConfirmButton>
                        </ButtonWrapper>
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default AdminModal