import React, { createContext, useState, useEffect } from 'react'

export const AuthContext = createContext()

export const AuthProvider = ({ initialUser, children }) => {
    const [loggedInUser, setLoggedInUser] = useState(initialUser || { loggedIn: false })
    
    // This useEffect ensures the loggedInUser state updates when initialUser changes
    useEffect(() => {
        if (initialUser) {
            setLoggedInUser(initialUser)
        }
    }, [initialUser])

    const updateUser = (userData) => {
        setLoggedInUser(userData)
    }

    return (
        <AuthContext.Provider value={{ loggedInUser, updateUser }}>
            {children}
        </AuthContext.Provider>
    )
}