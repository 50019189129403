export const addCommasToNumber = (number) => {
    // Return 0 or empty string if number is undefined/null
    if (number === undefined || number === null) {
        return '0'
    }
    
    // Convert to number if it's a string
    const num = typeof number === 'string' ? parseFloat(number) : number
    
    // Check if it's a valid number
    if (isNaN(num)) {
        return '0'
    }
    
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const removeCommasFromNumber = number => {
    return number ? number.replace(/,/g, '') : '';
}

export const formatNumber = (number) => {
    let formattedNumber

    if (number >= 1000000) {
        formattedNumber = (number / 1000000).toFixed(2)
    } else if (number >= 4230) {
        formattedNumber = (number / 1000).toFixed(2)
    } else {
        return number.toString()
    }

    // Remove trailing zeros and the decimal point if necessary
    formattedNumber = parseFloat(formattedNumber).toString()

    if (number >= 1000000) {
        return formattedNumber + 'M'
    } else {
        return formattedNumber + 'K'
    }
}