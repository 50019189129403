import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
    CardContainer,
    Overlay,
    OverlayName,
    OverlayRoles,
    OverlayText,
    ProjectImageContainer,
    ProjectCreatorContainer,
    ProjectCreatorImage,
    ProjectImage,
    CreatorName,
    DividerProgress,
    Divider,
    RaisedWrapper,
    RaisedTitle,
    RaisedSubtitle,
} from './DiscoverElements'

import { 
    Body,
    Status,
    Title,
    Logline,
} from '../Home/FeaturedProjects/FilmCard/FilmCardElements'

import { FaUser } from "react-icons/fa"

import { addCommasToNumber } from '../../helpers/numberConvert'

function FilmCard({
    image, 
    title, 
    logline, 
    artist, 
    currentFunds,
    totalExpressedInterest,
    expressedInterestBackers,
    targetFundAmount,
    investors,
    projectFundingType,
    projectStatus,
    onClick,
    home
}) {
    const [isHovered, setIsHovered] = useState(false)
    const [imageError, setImageError] = useState('')
    const navigate = useNavigate()

    const handleImageError = () => {
        setImageError(true)
    }

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...'
        }
        return text
    }

    const handleArtistNavigate = () => {
        navigate(`/artist-profile/${artist.slug}`)
    }

    // Convert targetFundAmount to a number
    const targetFundAmountNumber = parseFloat(targetFundAmount)

    // Calculate the percentage
    const fundingPercentage = (currentFunds / targetFundAmountNumber) * 100
    const interestPercentage = (totalExpressedInterest / targetFundAmountNumber) * 100

    let projectTypeString

    if (projectFundingType === 'REG_CF') 
        projectTypeString = 'REG CF'
    else if (projectFundingType === 'FIXED_REG_CF')
        projectTypeString = 'FIXED REG CF'
    else if (projectFundingType === 'REG_A_TIER_ONE')
        projectTypeString = 'REG A TIER ONE'
    else if (projectFundingType === 'REG_A_TIER_TWO')
        projectTypeString = 'REG A TIER TWO'

    let status

    if (projectStatus === 'DRAFT')
        status = 'DRAFT'
    else if (projectStatus === 'GAUGING_INTEREST')
        status = 'GAUGING INTEREST'
    else if (projectStatus === 'FUNDING')
        status = 'FUNDING'
    else if (projectStatus === 'FUNDED')
        status = 'FUNDED'
    else if (projectStatus === 'COMPLETED')
        status = 'COMPLETED'
    else if (projectStatus === 'CANCELLED')
        status = 'CANCELLED'

    const formattedRoles = artist?.artistRoles?.join(' | ') || ''

    return (
        <CardContainer 
            style={home ? { width: 350 } : {  }}
            onClick={onClick} 
        >
            <ProjectImageContainer
                style={home ? { width: 350 } : {  }}
            >
                <Overlay style={isHovered ? { opacity: 0.7 } : {}} />
                <OverlayRoles style={isHovered ? { opacity: 1 } : {}}>{formattedRoles}</OverlayRoles>
                <OverlayName style={isHovered ? { opacity: 1 } : {}} >{artist?.artistName}</OverlayName>
                <OverlayText style={isHovered ? { opacity: 1 } : {}}>{artist?.artistMiniBio}</OverlayText>
                <ProjectImage src={`${image}`} alt='poster image' className='poster'/>
            </ProjectImageContainer>
            <ProjectCreatorContainer 
                onMouseEnter={() => setIsHovered(true)} 
                onMouseLeave={() => setIsHovered(false)}
                onMouseUp={handleArtistNavigate}
            >
                {!imageError ? (
                    <ProjectCreatorImage 
                        alt='creator image' 
                        src={`${artist?.artistProfileImage}`} 
                        onError={handleImageError}
                    />
                ) : (
                    <FaUser style={isHovered ? { fontSize: '50px', color: 'white' } : { fontSize: '30px', color: 'black' }} />
                )}
            </ProjectCreatorContainer>
            <Body>
                <Status>{status} | {projectTypeString}</Status>
                <Title>{title}</Title>
                <CreatorName>Created by {artist?.artistName}</CreatorName>
                <Logline>{truncateText(logline, 160)}</Logline>
                <RaisedWrapper>
                    <Divider>
                        <DividerProgress style={status === 'GAUGING INTEREST' ? { width: `${interestPercentage}%` } : { width: `${fundingPercentage}%` }} />
                    </Divider>
                    <div style={{ display: 'flex' }}>
                        <RaisedTitle>${status === 'GAUGING INTEREST' ? addCommasToNumber(totalExpressedInterest) : addCommasToNumber(currentFunds)}</RaisedTitle>
                        <RaisedSubtitle>from {status === 'GAUGING INTEREST' ? addCommasToNumber(expressedInterestBackers) : addCommasToNumber(investors)} {investors === 1 ? 'investor' : 'investors'}</RaisedSubtitle>
                    </div>
                </RaisedWrapper>
            </Body>
        </CardContainer>
    )
}

export default FilmCard