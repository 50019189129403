import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../../../config'

import {
    UnfollowButton,
    FollowContainer,
    ProfilePicture,
    NoImageWrapper,
    Name,
    Description,
} from './FollowerElements'
import { FaUser } from 'react-icons/fa'

const ArtistFollowCard = ({
    follow,
    index,
    onUnfollow, // Add this prop
}) => {
    const [imageError, setImageError] = useState(false)
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(`/artist-profile/${follow?.artist?.slug}`)
    }

    const handleImageError = () => {
        setImageError(true)
    }

    const handleUnfollowClick = async (event) => {
        event.stopPropagation()
        try {
            await axios.post(
                `${API_URL}/discover-artist/unfollow`,
                { followedArtistId: follow?.artist?._id },
                { withCredentials: true }
            )
            // Call the onUnfollow callback with the unfollowed artist's ID
            onUnfollow(follow?.artist?._id)
        } catch (e) {
            console.error(e)
        }
    }

    const truncatedBio = follow?.artist?.artistMiniBio && follow?.artist?.artistMiniBio?.length > 100
        ? `${follow?.artist?.artistMiniBio?.substring(0, 100)}...`
        : follow?.artist?.artistMiniBio

    const imageUrl = follow?.artist?.artistProfileImage

    return (
        <FollowContainer style={index > 0 ? { marginLeft: 10 } : {}} onClick={handleNavigate}>
            {!imageError ? (
                <ProfilePicture
                    src={imageUrl}
                    alt={follow?.artist?.artistName}
                    onError={handleImageError}
                />
            ) : (
                <NoImageWrapper>
                    <FaUser size={50} color='white' />
                </NoImageWrapper>
            )}
            <Name>{follow?.artist?.artistName}</Name>
            <Description>{truncatedBio}</Description>
            <UnfollowButton
                onClick={handleUnfollowClick}
            >
                Unfollow
            </UnfollowButton>
        </FollowContainer>
    )
}

export default ArtistFollowCard