import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { CDN_URL } from '../../../config'
import Typewriter from 'typewriter-effect'
import { words } from './words'
import { throttle } from 'lodash'
import {
    Wrapper,
    VideoBackground,
    HeaderTextWrapper,
    HeaderText,
    SubHeaderText,
    ButtonWrapper,
    Button,
    ButtonBlack
} from './HeaderElements'
import { PiArrowDownThin } from 'react-icons/pi'

function Header() {
    const videoRef = useRef()
    const playRequestRef = useRef(null)
    const isComponentMounted = useRef(true)
    const [selectedWord, setSelectedWord] = useState('')
    const navigate = useNavigate()

    const handleVideoPlay = async () => {
        if (!videoRef.current || !isComponentMounted.current) return

        try {
            // Cancel any existing play request
            if (playRequestRef.current) {
                playRequestRef.current.abort()
            }

            // Create new AbortController for this play request
            const abortController = new AbortController()
            playRequestRef.current = abortController

            // Add signal to track when component unmounts
            await Promise.race([
                videoRef.current.play(),
                new Promise((_, reject) => {
                    abortController.signal.addEventListener('abort', () => {
                        reject(new Error('Play request aborted'))
                    })
                })
            ])
        } catch (err) {
            // Ignore any abort-related errors (both AbortError and manually aborted requests)
            if (err.name !== 'AbortError' && !err.message?.includes('aborted')) {
                console.error('Unexpected video playback error:', err)
            }
        } finally {
            playRequestRef.current = null
        }
    }

    const checkScroll = useCallback(async () => {
        if (!videoRef.current || !isComponentMounted.current) return

        if (window.pageYOffset > 800) {
            // If scrolled past threshold, pause and reset video
            try {
                await handleVideoPlay()
                videoRef.current.pause()
                videoRef.current.currentTime = 0
            } catch (err) {
                console.log('Error handling scroll video state:', err)
            }
        } else {
            await handleVideoPlay()
        }
    }, [])

    useEffect(() => {
        const randomWord = words[Math.floor(Math.random() * words.length)]
        setSelectedWord(randomWord)

        const throttledCheckScroll = throttle(checkScroll, 200)
        window.addEventListener('scroll', throttledCheckScroll)

        // Initial play attempt
        handleVideoPlay()

        return () => {
            isComponentMounted.current = false
            
            // Cleanup scroll listener
            window.removeEventListener('scroll', throttledCheckScroll)
            
            // Abort any pending play request
            if (playRequestRef.current) {
                playRequestRef.current.abort()
            }
        }
    }, [checkScroll])

    useEffect(() => {
        const changeWord = () => {
            const randomWord = words[Math.floor(Math.random() * words.length)]
            setSelectedWord(randomWord)
        }

        const intervalId = setInterval(changeWord, 25000)

        return () => {
            clearInterval(intervalId);
        }
    }, [])

    const handleProjectsClick = () => {
        navigate('/discover')
    }

    return (
        <Wrapper>
            <VideoBackground>
                <video
                    ref={videoRef}
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster={`${CDN_URL}/home/Muugen Homepage Poster.jpeg`}
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                >
                    <source src={`${CDN_URL}/home/Muugen Homepage Video.mov`} type="video/mp4" />
                </video>
                <div className="content">
                    {/* Content here */}
                </div>
            </VideoBackground>
            <HeaderTextWrapper>
                <HeaderText>
                    <Typewriter
                        options={{
                            strings: [`${selectedWord} independent funding.`],
                            autoStart: true,
                            loop: true,
                            delay: 50,
                            deleteSpeed: 50,
                            pauseFor: 25000,
                        }}
                    />
                    <SubHeaderText>
                        Join our community and invest in independent films and series with zero platform fees for both investors and filmmakers.
                    </SubHeaderText>
                </HeaderText>
                <ButtonWrapper>
                    <Button onClick={handleProjectsClick}>Start Investing</Button>
                    <ButtonBlack onClick={() => navigate('/posts')}>Learn More</ButtonBlack>
                </ButtonWrapper>
            </HeaderTextWrapper>
            <div style={{ position: 'absolute', bottom: 30, right: 30, color: 'white', zIndex: 1000 }}>
                <PiArrowDownThin size={50} color={'white'} aria-label="Scroll Down Arrow" />
            </div>
        </Wrapper>
    )
}

export default Header