import React, { useContext } from 'react'
import NonMainNavbar from '../../components/NonMainNav'
import { AuthContext } from '../../authContext'
import {
    TextContainer,
    Section,
    SubSection,
    List,
    ListItem,
    // ContactInfo,
    LastUpdated,
    // ImportantNote,
    // DefinitionsSection,
    // Definition,
    // DefinitionDropdown,
    // DropdownHeader,
    // DropdownContent
} from '../../PolicyElements.js'

const Cookies = () => {
    // const [isOpen, setIsOpen] = useState(false)

    const { loggedInUser } = useContext(AuthContext)

    return (
        <div style={{ overflowX: 'hidden', background: 'white' }}>
            <NonMainNavbar loggedInUser={loggedInUser} />
            <TextContainer>
                <h1>Cookie Policy of www.invest.muugen.com</h1>
                <LastUpdated>Last Updated: December 17, 2024</LastUpdated>
                <p>This Cookie Policy explains how our website uses cookies and similar technologies to provide, customize, evaluate, improve, and secure our services.</p>

                <Section>
                    <h2>What Are Cookies?</h2>
                    <p>Cookies are small text files that are stored on your device when you visit our website. They help us remember your preferences and make your browsing experience more efficient and secure.</p>
                </Section>

                <Section>
                    <h2>Cookies We Use</h2>

                    <h3>Essential Cookies</h3>
                    <p>These cookies are necessary for the website to function properly and cannot be switched off in our systems.</p>

                    <h4>Authentication Token (JWT)</h4>
                    <List>
                        <ListItem>Name: <b>token</b></ListItem>
                        <ListItem>Purpose: Maintains your authenticated session</ListItem>
                        <ListItem>Duration: Session-based</ListItem>
                        <ListItem>Type: HTTP Only</ListItem>
                        <ListItem>Security Level: Strict</ListItem>
                        <ListItem>Additional Details: Contains encrypted user identification and session information</ListItem>
                        <ListItem>Storage Duration: Until session ends or logout</ListItem>
                    </List>

                    <h4>Token Presence Indicator</h4>
                    <List>
                        <ListItem>Name: <b>tokenPresence</b></ListItem>
                        <ListItem>Purpose: Indicates active authentication status to client-side applications</ListItem>
                        <ListItem>Duration: Matches authentication token</ListItem>
                        <ListItem>Type: Client accessible</ListItem>
                        <ListItem>Security Level: Lax</ListItem>
                        <ListItem>Additional Details: Boolean value indicating token status</ListItem>
                        <ListItem>Storage Duration: Matches main authentication token</ListItem>
                    </List>

                    <h4>User Type</h4>
                    <List>
                        <ListItem>Name: <b>userType</b></ListItem>
                        <ListItem>Purpose: Stores user role information for UI customization</ListItem>
                        <ListItem>Duration: Session-based</ListItem>
                        <ListItem>Type: Client accessible</ListItem>
                        <ListItem>Security Level: Lax</ListItem>
                        <ListItem>Additional Details: Contains user role identifier</ListItem>
                        <ListItem>Storage Duration: Matches authentication session</ListItem>
                    </List>
                </Section>

                <Section>
                    <h2>Cookie Management</h2>

                    <SubSection>
                        <h3>Security Measures</h3>
                        <List>
                            <ListItem>All authentication cookies are encrypted</ListItem>
                            <ListItem>HTTP Only flags are used where appropriate</ListItem>
                            <ListItem>Secure flag is enabled in production environment</ListItem>
                            <ListItem>SameSite attributes are set to &apos;Lax&apos; for balanced security</ListItem>
                        </List>
                    </SubSection>
                </Section>

                <Section>
                    <h2>Your Rights and Choices</h2>

                    <SubSection>
                        <h3>Managing Cookie Preferences</h3>

                        <List>
                            <ListItem>Essential cookies cannot be disabled as they are necessary for the website&apos;s basic functionality</ListItem>
                            <ListItem>You can clear cookies through your browser settings</ListItem>
                            <ListItem>Blocking all cookies will prevent you from accessing authenticated areas of the website</ListItem>
                        </List>
                    </SubSection>

                    <SubSection>
                        <h3>Browser Settings</h3>

                        <p>Most web browsers allow you to manage cookies through their settings:</p>

                        <List>
                            <ListItem>Chrome: Settings → Privacy and Security → Cookies and other site data</ListItem>
                            <ListItem>Firefox: Options → Privacy & Security → Cookies and Site Data</ListItem>
                            <ListItem>Safari: Preferences → Privacy → Cookies and website data</ListItem>
                            <ListItem>Edge: Settings → Cookies and site permissions → Cookies and site data</ListItem>
                        </List>
                    </SubSection>
                </Section>

                <Section>
                    <h2>Updates to This Policy</h2>

                    <p>We may update this Cookie Policy from time to time. The latest version will always be available on our website, with the &quot;Last Updated&quot; date at the top of the policy.</p>
                </Section>

                <Section>
                    <h2>Legal Compliance</h2>

                    <p>This Cookie Policy is designed to comply with applicable data protection laws, including:</p>

                    <List>
                        <ListItem>General Data Protection Regulation (GDPR)</ListItem>
                        <ListItem>ePrivacy Directive</ListItem>
                        <ListItem>California Consumer Privacy Act (CCPA)</ListItem>
                    </List>
                </Section>
            </TextContainer>
        </div>
    )
}

export default Cookies