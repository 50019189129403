import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    min-height: 800px;
    color: white;
    position: relative;
    overflow: hidden;
    padding-left: 50px;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding-left: 0;
    }
`

export const VideoBackground = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 800px;
    background: black;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translate(-50%, -50%);
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }

    .content {
        position: relative;
        z-index: 2;
    }
`

export const HeaderTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    z-index: 3;
    width: 500px;
    height: 400px;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding-left: 70px;
        padding-right: 70px;
    }

    /* @media screen and (max-width: 560px) {
        width: 450px;
    } */
`

export const HeaderText = styled.div`
    width: 100%;
    font-size: 40px;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        font-size: 35px;
        text-align: center;
    }

    @media screen and (max-width: 560px) {
        font-size: 40px;
    }
`

export const SubHeaderText = styled.div`
    margin-top: 5px;
    width: 100%;
    font-size: 19px;
    font-weight: 300;

    @media only screen and (max-width: 768px) {
        font-size: 19px;
        margin-bottom: 20px;
        /* body {
            text-align: center;
        } */
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const Button = styled.button`
    width: 50%;
    padding: 20px;
    font-size: 16px;
    background-color: black;
    border: 1px solid black;
    font-weight: bold;
    color: white;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 2px;
    transition: all 0.1s ease-in-out;

    &:hover {
        /* background: #f5d782; */
        font-size: 16.5px;
        transform: scale(1.02);
    }

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`

export const ButtonBlack = styled.button`
    width: 50%;
    padding: 20px;
    font-size: 16px;
    margin-top: 10px;
    background-color: transparent;
    border: thin solid #f5f4f4;
    font-weight: bold;
    color: white;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 2px;
    transition: all 0.1s ease-in-out;

    &:hover {
        border-color: white;
        font-size: 16.5px;
        transform: scale(1.02);
    }

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;