import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { AuthContext } from '../../authContext'
import axios from 'axios'
import { API_URL } from '../../config'
import NonMainNav from '../NonMainNav'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useQueryClient } from '@tanstack/react-query'

import {
    CheckboxText,
    CheckboxLink,
    InputWrapper,
    SignupError,
} from '../Signup/SignupElements.js'

import {
    Wrapper,
    PasswordWrapper,
    PasswordInput,
    EyeIcon,
    LoginWrapper, 
    LoginHeader,
    LoginInput,
    LoginButton 
} from './LoginElements.js'

function LoginComponent() {
    const queryClient = useQueryClient()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loginError, setLoginError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [searchParams] = useSearchParams()

    const navigate = useNavigate()
    const location = useLocation()
   
    const { loggedInUser, updateUser } = useContext(AuthContext)

    useEffect(() => {
        if (loggedInUser?.loggedIn) {
            const redirectPath = searchParams.get('redirect')
            if (redirectPath) {
                // Decode the URL-encoded redirect path
                const decodedPath = decodeURIComponent(redirectPath)
                navigate(decodedPath)
            } else {
                navigate('/')
            }
        }
    }, [loggedInUser, navigate, searchParams])

    useEffect(() => {
        // Clear the state when user navigates within the login flow
        // This ensures normal login behavior is restored
        return () => {
            if (location.pathname !== '/login') {
                navigate(location.pathname, { replace: true, state: {} })
            }
        }
    }, [location, navigate])

    const handleLogin = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setLoginError('')
        setEmailError('')
        setPasswordError('')
    
        try {
            if (!email) {
                setEmailError('Please enter your email.')
                setIsLoading(false)
                return
            }
    
            if (!password) {
                setPasswordError('Please enter your password.')
                setIsLoading(false)
                return
            }
    
            const response = await axios.post(`${API_URL}/login`, { email, password }, { withCredentials: true })
    
            if (response.data.error) {
                setLoginError(response.data.error)
            } else {
                // Update context directly
                updateUser({
                    ...response.data,
                    loggedIn: true
                })
                
                // Also update query cache to keep in sync
                queryClient.setQueryData(['user'], {
                    ...response.data,
                    loggedIn: true
                })
            }
        } catch (error) {
            console.error('There was an error during login:', error)
            setLoginError('An error occurred during login. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        return () => {
            if (location.pathname !== '/login') {
                navigate(location.pathname, { replace: true, state: {} })
            }
        }
    }, [location, navigate])

    return (
        <div>
            <NonMainNav loggedInUser={loggedInUser} />
            <Wrapper>
                <LoginWrapper>
                    <LoginHeader>Muugen Invest</LoginHeader>
                        <InputWrapper>
                            <LoginInput 
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                style={emailError ? { borderColor: 'red' } : {}} 
                                placeholder='Email' 
                            />
                            <SignupError>{emailError}</SignupError>
                        </InputWrapper>
                        <PasswordWrapper>
                            <PasswordInput 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={passwordError ? { borderColor: 'red' } : {}}
                                type={showPassword ? 'text' : 'password'}
                                id='password-input' 
                                placeholder='Password' 
                            />
                            <EyeIcon style={{ marginTop: -3 }} onClick={() => setShowPassword(!showPassword)}>
                                {!showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                            </EyeIcon>
                            <SignupError>{passwordError}</SignupError>
                        </PasswordWrapper>
                        <LoginButton onClick={handleLogin} type="submit" disabled={isLoading}>
                            {isLoading ? 'Logging in...' : 'Log in'}
                        </LoginButton>
                        <SignupError>{loginError}</SignupError>
                </LoginWrapper>
                <LoginWrapper>
                    <CheckboxText>Don&apos;t have an account? <CheckboxLink onClick={() => navigate('/sign-up')} >Sign up</CheckboxLink> instead.</CheckboxText>
                    <CheckboxText style={{ marginTop: 10 }}><CheckboxLink onClick={() => navigate('/password/reset')} >Forgot password?</CheckboxLink></CheckboxText>
                </LoginWrapper>
            </Wrapper>
        </div>
    )
}

export default LoginComponent